const datav7 =
{
    "IR08572-NW_NIRCam_2024-03-08": {
        "target_name": "IR08572-NW",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A JWST Survey of Ultraluminous Infrared Galaxies",
        "parent_obsid": "212641177",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Infrared galaxies, Interacting galaxies",
        "file_name": "jw03368-o111_t005_nircam_clear-f356w_i2d.fits",
        "start_time": "2024-03-08 06:19:13.557",
        "end_time": "2024-03-08 06:40:31.273",
        "exposure_time": "0:09:39.789000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03368-o111_t005_nircam_clear-f356w_i2d.fits",
        "size": 150076800
    },
    "IR05189_NIRCam_2024-02-19": {
        "target_name": "IR05189",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "A JWST Survey of Ultraluminous Infrared Galaxies",
        "parent_obsid": "212511801",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Infrared galaxies, Interacting galaxies",
        "file_name": "jw03368-o106_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2024-02-19 04:18:57.523",
        "end_time": "2024-02-19 04:40:04.487",
        "exposure_time": "0:09:39.789000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03368-o106_t001_nircam_clear-f277w_i2d.fits",
        "size": 150134400
    },
    "NGC1637_NIRCam_2024-02-05": {
        "target_name": "NGC1637",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "210094593",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o016_t009_nircam_clear-f335m_i2d.fits",
        "start_time": "2024-02-05 16:29:57.278",
        "end_time": "2024-02-05 16:40:52.230",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o016_t009_nircam_clear-f335m_i2d.fits",
        "size": 132111360
    },
    "URANUS_NIRCam_2024-02-06": {
        "target_name": "URANUS",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F460M",
        "obs_title": "JWST Cycle 1 Outreach Campaign",
        "parent_obsid": "173671567",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet",
        "file_name": "jw02739-o011_t002_nircam_clear-f460m_i2d.fits",
        "start_time": "2023-09-04 22:34:05.732",
        "end_time": "2023-09-05 00:15:33.525",
        "exposure_time": "0:30:25.250000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02739-o011_t002_nircam_clear-f460m_i2d.fits",
        "size": 137859840
    },
    "NGC-4303-TILE-1_NIRCam_2024-02-06": {
        "target_name": "NGC-4303-TILE-1",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "210371117",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o044_t020_nircam_clear-f360m_i2d.fits",
        "start_time": "2024-02-06 17:21:19.938",
        "end_time": "2024-02-06 17:42:26.918",
        "exposure_time": "0:07:09.472000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o044_t020_nircam_clear-f360m_i2d.fits",
        "size": 132128640
    },
    "URANUS_NIRCam_2024-02-07": {
        "target_name": "URANUS",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F460M",
        "obs_title": "JWST Cycle 1 Outreach Campaign",
        "parent_obsid": "173671567",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet",
        "file_name": "jw02739-o011_t002_nircam_clear-f460m_i2d.fits",
        "start_time": "2023-09-04 22:34:05.732",
        "end_time": "2023-09-05 00:15:33.525",
        "exposure_time": "0:30:25.250000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02739-o011_t002_nircam_clear-f460m_i2d.fits",
        "size": 137859840
    },
    "NGC2283_NIRCam_2024-02-08": {
        "target_name": "NGC2283",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F300M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "210675880",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o024_t014_nircam_clear-f300m_i2d.fits",
        "start_time": "2024-02-08 12:43:11.693",
        "end_time": "2024-02-08 12:51:14.866",
        "exposure_time": "0:03:34.736000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o024_t014_nircam_clear-f300m_i2d.fits",
        "size": 132111360
    },
    "HUDF-DEEP-F160W_NIRISS_2024-01-30": {
        "target_name": "HUDF-DEEP-F160W",
        "target_classification": "Galaxy",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F200W",
        "obs_title": "The Next Generation Deep Extragalactic Exploratory Public (NGDEEP) Survey: Feedback in Low-Mass Galaxies from Cosmic Dawn to Dusk",
        "parent_obsid": "113644309",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Emission line galaxies, High-redshift galaxies, Lyman-alpha galaxies, Lyman-break galaxies",
        "file_name": "jw02079-o004_t001_niriss_clear-f200w_i2d.fits",
        "start_time": "2023-02-01 15:44:31.167",
        "end_time": "2023-02-02 01:21:37.554",
        "exposure_time": "0:28:37.880000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02079-o004_t001_niriss_clear-f200w_i2d.fits",
        "size": 138075840
    },
    "HUDF-DEEP-F160W_NIRISS_2024-02-01": {
        "target_name": "HUDF-DEEP-F160W",
        "target_classification": "Galaxy",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F200W",
        "obs_title": "The Next Generation Deep Extragalactic Exploratory Public (NGDEEP) Survey: Feedback in Low-Mass Galaxies from Cosmic Dawn to Dusk",
        "parent_obsid": "113644309",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Emission line galaxies, High-redshift galaxies, Lyman-alpha galaxies, Lyman-break galaxies",
        "file_name": "jw02079-o004_t001_niriss_clear-f200w_i2d.fits",
        "start_time": "2023-02-01 15:44:31.167",
        "end_time": "2023-02-02 01:21:37.554",
        "exposure_time": "0:28:37.880000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02079-o004_t001_niriss_clear-f200w_i2d.fits",
        "size": 138075840
    },
    "HUDF-DEEP-F160W_NIRISS_2024-01-23": {
        "target_name": "HUDF-DEEP-F160W",
        "target_classification": "Galaxy",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F200W",
        "obs_title": "The Next Generation Deep Extragalactic Exploratory Public (NGDEEP) Survey: Feedback in Low-Mass Galaxies from Cosmic Dawn to Dusk",
        "parent_obsid": "113644309",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Emission line galaxies, High-redshift galaxies, Lyman-alpha galaxies, Lyman-break galaxies",
        "file_name": "jw02079-o004_t001_niriss_clear-f200w_i2d.fits",
        "start_time": "2023-02-01 15:44:31.167",
        "end_time": "2023-02-02 01:21:37.554",
        "exposure_time": "0:28:37.880000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02079-o004_t001_niriss_clear-f200w_i2d.fits",
        "size": 138075840
    },
    "NGC1792_NIRCam_2024-01-17": {
        "target_name": "NGC1792",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "204349169",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o020_t011_nircam_clear-f335m_i2d.fits",
        "start_time": "2024-01-17 18:40:30.354",
        "end_time": "2024-01-17 18:51:25.305",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o020_t011_nircam_clear-f335m_i2d.fits",
        "size": 132050880
    },
    "NGC1068_NIRCam_2024-01-08": {
        "target_name": "NGC1068",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "200910703",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o128_t002_nircam_clear-f335m_i2d.fits",
        "start_time": "2024-01-08 04:30:13.231",
        "end_time": "2024-01-08 04:41:08.246",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o128_t002_nircam_clear-f335m_i2d.fits",
        "size": 132111360
    },
    "Requiem2_NIRCam_2024-01-08": {
        "target_name": "Requiem2",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "Lensed Supernova Encore at z=2! The First Galaxy to Host Two Multiply-Imaged Supernovae",
        "parent_obsid": "212634225",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Type Ia supernovae",
        "file_name": "jw06549-c1000_t001_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-12-05 21:43:22.165",
        "end_time": "2023-12-23 20:10:29.226",
        "exposure_time": "0:48:40.404000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw06549-c1000_t001_nircam_clear-f444w_i2d.fits",
        "size": 173396160
    },
    "NGC1808_NIRCam_2024-01-09": {
        "target_name": "NGC1808",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "201206295",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o122_t012_nircam_clear-f335m_i2d.fits",
        "start_time": "2024-01-09 14:42:18.423",
        "end_time": "2024-01-09 14:53:13.374",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o122_t012_nircam_clear-f335m_i2d.fits",
        "size": 131990400
    },
    "MACSJ0416.1-2403_NIRISS_2024-01-09": {
        "target_name": "MACSJ0416.1-2403",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F115W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "183624080",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o004_t002_niriss_clear-f115w_i2d.fits",
        "start_time": "2023-01-11 20:31:47.513",
        "end_time": "2023-01-12 00:42:55.092",
        "exposure_time": "0:22:54.308000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o004_t002_niriss_clear-f115w_i2d.fits",
        "size": 133983360
    },
    "NGC-4321_NIRCam_2024-01-10": {
        "target_name": "NGC-4321",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "109838029",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o032_t014_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-01-17 10:50:25.669",
        "end_time": "2023-01-17 11:11:32.649",
        "exposure_time": "0:07:09.472000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o032_t014_nircam_clear-f360m_i2d.fits",
        "size": 132186240
    },
    "ABELL370_NIRISS_2023-12-26": {
        "target_name": "ABELL370",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F115W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "107461690",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Abell clusters, Rich clusters",
        "file_name": "jw01208-o002_t001_niriss_clear-f115w_i2d.fits",
        "start_time": "2022-12-29 06:11:44.632",
        "end_time": "2022-12-29 10:27:32.331",
        "exposure_time": "0:22:54.308000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o002_t001_niriss_clear-f115w_i2d.fits",
        "size": 133983360
    },
    "NGC2090_NIRCam_2023-12-27": {
        "target_name": "NGC2090",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "199453177",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o022_t013_nircam_clear-f335m_i2d.fits",
        "start_time": "2023-12-27 08:44:57.220",
        "end_time": "2023-12-27 08:55:52.172",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o022_t013_nircam_clear-f335m_i2d.fits",
        "size": 132111360
    },
    "NGC1097_NIRCam_2023-12-27": {
        "target_name": "NGC1097",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F300M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "199453505",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o004_t003_nircam_clear-f300m_i2d.fits",
        "start_time": "2023-12-27 12:02:59.644",
        "end_time": "2023-12-27 12:11:02.816",
        "exposure_time": "0:03:34.736000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o004_t003_nircam_clear-f300m_i2d.fits",
        "size": 132171840
    },
    "GJ-436_NIRCam_2023-12-21": {
        "target_name": "GJ-436",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "WLP4",
        "obs_title": "Transit Spectroscopy of Mature Planets",
        "parent_obsid": "107465145",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Exoplanet Systems, M dwarfs, M stars",
        "file_name": "jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "start_time": "2022-12-31 22:16:05.494",
        "end_time": "2023-01-01 03:03:09.120",
        "exposure_time": "4:20:57.842000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "size": 79280640
    },
    "Requiem2_NIRCam_2023-12-23": {
        "target_name": "Requiem2",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "Lensed Supernova Encore at z=2! The First Galaxy to Host Two Multiply-Imaged Supernovae",
        "parent_obsid": "212634225",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Type Ia supernovae",
        "file_name": "jw06549-c1000_t001_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-12-05 21:43:22.165",
        "end_time": "2023-12-23 20:10:29.226",
        "exposure_time": "0:48:40.404000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw06549-c1000_t001_nircam_clear-f444w_i2d.fits",
        "size": 173396160
    },
    "Requiem2_NIRCam_2023-12-06": {
        "target_name": "Requiem2",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "Lensed Supernova Encore at z=2! The First Galaxy to Host Two Multiply-Imaged Supernovae",
        "parent_obsid": "212634225",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Type Ia supernovae",
        "file_name": "jw06549-c1000_t001_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-12-05 21:43:22.165",
        "end_time": "2023-12-23 20:10:29.226",
        "exposure_time": "0:48:40.404000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw06549-c1000_t001_nircam_clear-f444w_i2d.fits",
        "size": 173396160
    },
    "GJ-436_NIRCam_2023-12-07": {
        "target_name": "GJ-436",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "WLP4",
        "obs_title": "Transit Spectroscopy of Mature Planets",
        "parent_obsid": "107465145",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Exoplanet Systems, M dwarfs, M stars",
        "file_name": "jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "start_time": "2022-12-31 22:16:05.494",
        "end_time": "2023-01-01 03:03:09.120",
        "exposure_time": "4:20:57.842000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "size": 79280640
    },
    "C26202_NIRCam_2023-11-30": {
        "target_name": "C26202",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F410M",
        "obs_title": "NIRCam Full-Subarray Flux Transfer",
        "parent_obsid": "158420359",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw04452-o012_t029_nircam_clear-f410m_i2d.fits",
        "start_time": "2023-07-31 05:29:31.488",
        "end_time": "2023-07-31 05:36:51.732",
        "exposure_time": "0:02:51.788000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw04452-o012_t029_nircam_clear-f410m_i2d.fits",
        "size": 121628160
    },
    "NGC1511_NIRCam_2023-11-25": {
        "target_name": "NGC1511",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "194937734",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o010_t006_nircam_clear-f335m_i2d.fits",
        "start_time": "2023-11-25 23:05:39.994",
        "end_time": "2023-11-25 23:16:34.946",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o010_t006_nircam_clear-f335m_i2d.fits",
        "size": 132111360
    },
    "NGC-253-IM-CENTER_NIRCam_2023-11-03": {
        "target_name": "NGC-253-IM-CENTER",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "Dissecting the Prototypical Starbursts NGC 253 and M 82 and Their Cool Galactic Winds",
        "parent_obsid": "198055454",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Disk galaxies, Starburst galaxies",
        "file_name": "jw01701-o057_t021_nircam_clear-f335m_i2d.fits",
        "start_time": "2023-12-04 16:19:46.356",
        "end_time": "2023-12-04 16:34:16.098",
        "exposure_time": "0:13:35.994000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01701-o057_t021_nircam_clear-f335m_i2d.fits",
        "size": 287104320
    },
    "NGC1546_NIRCam_2023-10-11": {
        "target_name": "NGC1546",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F300M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "182665379",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o012_t007_nircam_clear-f300m_i2d.fits",
        "start_time": "2023-10-11 09:28:42.326",
        "end_time": "2023-10-11 09:36:45.498",
        "exposure_time": "0:03:34.736000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o012_t007_nircam_clear-f300m_i2d.fits",
        "size": 132171840
    },
    "IC5273_NIRCam_2023-10-04": {
        "target_name": "IC5273",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "180285205",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o118_t054_nircam_clear-f335m_i2d.fits",
        "start_time": "2023-10-04 15:44:17.271",
        "end_time": "2023-10-04 15:55:12.223",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o118_t054_nircam_clear-f335m_i2d.fits",
        "size": 132111360
    },
    "NGC7456_NIRCam_2023-10-04": {
        "target_name": "NGC7456",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "180305244",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o120_t055_nircam_clear-f335m_i2d.fits",
        "start_time": "2023-10-04 17:12:55.538",
        "end_time": "2023-10-04 17:23:50.490",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o120_t055_nircam_clear-f335m_i2d.fits",
        "size": 132111360
    },
    "NGC1809_NIRCam_2023-10-05": {
        "target_name": "NGC1809",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "180532258",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o018_t010_nircam_clear-f335m_i2d.fits",
        "start_time": "2023-10-05 08:12:39.742",
        "end_time": "2023-10-05 08:23:34.757",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o018_t010_nircam_clear-f335m_i2d.fits",
        "size": 132111360
    },
    "2MASS-J05215777-6930407_NIRCam_2023-10-05": {
        "target_name": "2MASS-J05215777-6930407",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "NIRCam Subarray Linearity",
        "parent_obsid": "180557490",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Long period variables",
        "file_name": "jw04448-o007_t005_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-10-05 12:12:46.011",
        "end_time": "2023-10-05 12:22:58.007",
        "exposure_time": "0:10:01.259000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw04448-o007_t005_nircam_clear-f277w_i2d.fits",
        "size": 120389760
    },
    "SGRA_NIRCam_2023-09-22": {
        "target_name": "SGRA",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F210M",
        "obs_title": "Imaging the Shadow of a Black Hole through Sgr A* Monitoring",
        "parent_obsid": "128095545",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Young star clusters",
        "file_name": "jw02235-o003_t001_nircam_clear-f210m-sub640_i2d.fits",
        "start_time": "2023-04-18 06:34:08.318",
        "end_time": "2023-04-18 12:44:41.893",
        "exposure_time": "5:01:22.824000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02235-o003_t001_nircam_clear-f210m-sub640_i2d.fits",
        "size": 77140800
    },
    "NAME-GAL-CENTER_NIRCam_2023-09-23": {
        "target_name": "NAME-GAL-CENTER",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "Galactic Center Astrometry",
        "parent_obsid": "88629460",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Stellar associations",
        "file_name": "jw01306-o001_t001_nircam_clear-f360m_i2d.fits",
        "start_time": "2022-08-10 09:27:24.905",
        "end_time": "2022-08-10 09:57:50.165",
        "exposure_time": "0:21:17.675000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01306-o001_t001_nircam_clear-f360m_i2d.fits",
        "size": 287870400
    },
    "NGC1559_NIRCam_2023-09-14": {
        "target_name": "NGC1559",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "A JWST Census of the Local Galaxy Population: Anchoring the Physics of the Matter Cycle",
        "parent_obsid": "175787321",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw03707-o014_t008_nircam_clear-f335m_i2d.fits",
        "start_time": "2023-09-14 05:59:41.081",
        "end_time": "2023-09-14 06:10:36.033",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw03707-o014_t008_nircam_clear-f335m_i2d.fits",
        "size": 132111360
    },
    "GRB221009A-OT_NIRCam_2023-09-04": {
        "target_name": "GRB221009A-OT",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Late Time Observations of GRB 221009A: The First Search for r-Process Nucleosynthesis in a Collapsar",
        "parent_obsid": "128929605",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Gamma Ray transients, Supernovae",
        "file_name": "jw02784-o002_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-04-22 07:08:41.132",
        "end_time": "2023-04-22 07:22:27.927",
        "exposure_time": "0:09:18.312000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02784-o002_t001_nircam_clear-f277w_i2d.fits",
        "size": 120804480
    },
    "URANUS_NIRCam_2023-09-04": {
        "target_name": "URANUS",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F460M",
        "obs_title": "JWST Cycle 1 Outreach Campaign",
        "parent_obsid": "173671567",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet",
        "file_name": "jw02739-o011_t002_nircam_clear-f460m_i2d.fits",
        "start_time": "2023-09-04 22:34:05.732",
        "end_time": "2023-09-05 00:15:33.525",
        "exposure_time": "0:30:25.250000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02739-o011_t002_nircam_clear-f460m_i2d.fits",
        "size": 137859840
    },
    "CPD-59-5831_NIRCam_2023-08-20": {
        "target_name": "CPD-59-5831",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "117935720",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183039001_03103_00001-seg001_nrca1_calints.fits",
        "start_time": "2023-02-22 02:31:57.219",
        "end_time": "2023-02-22 02:32:13.442",
        "exposure_time": "0:00:13.519000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183039001_03103_00001-seg001_nrca1_calints.fits",
        "size": 26323200
    },
    "PSF-STAR-SDSS-J0101+0007_NIRCam_2023-08-21": {
        "target_name": "PSF-STAR-SDSS-J0101+0007",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Unveiling Stellar Light from Host Galaxies of z~6 Quasars",
        "parent_obsid": "107477571",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "M stars",
        "file_name": "jw01813-o004_t010_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-01 15:50:08.218",
        "end_time": "2023-01-01 16:13:13.341",
        "exposure_time": "0:08:35.368000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01813-o004_t010_nircam_clear-f356w_i2d.fits",
        "size": 120628800
    },
    "SGRA_NIRCam_2023-08-24": {
        "target_name": "SGRA",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F210M",
        "obs_title": "Imaging the Shadow of a Black Hole through Sgr A* Monitoring",
        "parent_obsid": "128095545",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Young star clusters",
        "file_name": "jw02235-o003_t001_nircam_clear-f210m-sub640_i2d.fits",
        "start_time": "2023-04-18 06:34:08.318",
        "end_time": "2023-04-18 12:44:41.893",
        "exposure_time": "5:01:22.824000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02235-o003_t001_nircam_clear-f210m-sub640_i2d.fits",
        "size": 77140800
    },
    "PSF-STAR-SDSS-J0101+0007_NIRCam_2023-08-14": {
        "target_name": "PSF-STAR-SDSS-J0101+0007",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Unveiling Stellar Light from Host Galaxies of z~6 Quasars",
        "parent_obsid": "107477571",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "M stars",
        "file_name": "jw01813-o004_t010_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-01 15:50:08.218",
        "end_time": "2023-01-01 16:13:13.341",
        "exposure_time": "0:08:35.368000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01813-o004_t010_nircam_clear-f356w_i2d.fits",
        "size": 120628800
    },
    "NAME-GAL-CENTER_NIRCam_2023-08-20": {
        "target_name": "NAME-GAL-CENTER",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "Galactic Center Astrometry",
        "parent_obsid": "88629460",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Stellar associations",
        "file_name": "jw01306-o001_t001_nircam_clear-f360m_i2d.fits",
        "start_time": "2022-08-10 09:27:24.905",
        "end_time": "2022-08-10 09:57:50.165",
        "exposure_time": "0:21:17.675000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01306-o001_t001_nircam_clear-f360m_i2d.fits",
        "size": 287870400
    },
    "AT2023lcr_NIRCam_2023-08-07": {
        "target_name": "AT2023lcr",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F115W",
        "obs_title": "Revealing the progenitor of the dirty fireball gamma-ray burst AT 2023lcr through its supernova component",
        "parent_obsid": "163218024",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Gamma Ray bursters, Supernovae",
        "file_name": "jw04554-o001_t001_nircam_clear-f115w_i2d.fits",
        "start_time": "2023-08-07 13:06:25.243",
        "end_time": "2023-08-07 13:27:42.941",
        "exposure_time": "0:17:10.728000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw04554-o001_t001_nircam_clear-f115w_i2d.fits",
        "size": 143403840
    },
    "LAWD-66-SOURCE_NIRCam_2023-08-02": {
        "target_name": "LAWD-66-SOURCE",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F322W2;F323N",
        "obs_title": "Accurate Mass Determination of the Nearby Single White Dwarf L845-70 through Astrometric Microlensing",
        "parent_obsid": "87742342",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "K dwarfs",
        "file_name": "jw02692-o001_t001_nircam_f322w2-f323n_i2d.fits",
        "start_time": "2022-07-27 05:48:42.780",
        "end_time": "2022-07-27 06:15:54.788",
        "exposure_time": "0:21:28.412000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02692-o001_t001_nircam_f322w2-f323n_i2d.fits",
        "size": 120804480
    },
    "ANDROMEDA-XVII_NIRCam_2023-08-03": {
        "target_name": "ANDROMEDA-XVII",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Dynamics of the Andromeda Galaxy Satellite System",
        "parent_obsid": "87766841",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf galaxies, Dwarf spheroidal galaxies",
        "file_name": "jw01305-o004_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-07-29 00:55:48.498",
        "end_time": "2022-07-29 01:27:50.472",
        "exposure_time": "0:25:56.830000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01305-o004_t004_nircam_clear-f277w_i2d.fits",
        "size": 288276480
    },
    "ANDROMEDA-I_NIRCam_2023-08-04": {
        "target_name": "ANDROMEDA-I",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Dynamics of the Andromeda Galaxy Satellite System",
        "parent_obsid": "87770087",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf galaxies, Dwarf spheroidal galaxies",
        "file_name": "jw01305-o001_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-07-28 21:57:00.129",
        "end_time": "2022-07-28 22:29:02.034",
        "exposure_time": "0:25:56.830000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01305-o001_t001_nircam_clear-f277w_i2d.fits",
        "size": 288276480
    },
    "CD-40-11169_NIRCam_2023-07-30": {
        "target_name": "CD-40-11169",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "158294218",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183017001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2023-07-30 00:17:33.684",
        "end_time": "2023-07-30 00:17:49.907",
        "exposure_time": "0:00:13.519000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183017001_03103_00001-seg001_nrca3_calints.fits",
        "size": 26320320
    },
    "NAME-ZTF-J153932.16+502738.8_NIRCam_2023-07-18": {
        "target_name": "NAME-ZTF-J153932.16+502738.8",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Validating JWSTs in-orbit clock accuracy",
        "parent_obsid": "114180752",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Compact binary systems, White dwarfs",
        "file_name": "jw01666001001_02102_00001-seg003_nrcblong_calints.fits",
        "start_time": "2023-02-05 19:02:07.281",
        "end_time": "2023-02-06 04:28:41.361",
        "exposure_time": "8:58:14.376000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw01666001001_02102_00001-seg003_nrcblong_calints.fits",
        "size": 153809280
    },
    "IC-5332_NIRCam_2023-07-11": {
        "target_name": "IC-5332",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "150961575",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o042_t017_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-07-11 19:52:46.968",
        "end_time": "2023-07-11 20:13:53.948",
        "exposure_time": "0:07:09.472000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o042_t017_nircam_clear-f360m_i2d.fits",
        "size": 132125760
    },
    "P330E_NIRCam_2023-07-16": {
        "target_name": "P330E",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F405N",
        "obs_title": "Absolute Flux Calibration (G Dwarfs)",
        "parent_obsid": "87623322",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "start_time": "2022-07-02 17:53:27.661",
        "end_time": "2022-07-02 17:56:08.688",
        "exposure_time": "0:01:04.420000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "size": 120752640
    },
    "NGC-253-IM-CENTER_NIRCam_2023-07-01": {
        "target_name": "NGC-253-IM-CENTER",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "Dissecting the Prototypical Starbursts NGC 253 and M 82 and Their Cool Galactic Winds",
        "parent_obsid": "198055454",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Disk galaxies, Starburst galaxies",
        "file_name": "jw01701-o057_t021_nircam_clear-f335m_i2d.fits",
        "start_time": "2023-12-04 16:19:46.356",
        "end_time": "2023-12-04 16:34:16.098",
        "exposure_time": "0:13:35.994000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01701-o057_t021_nircam_clear-f335m_i2d.fits",
        "size": 287104320
    },
    "SATURN-CENTRE_NIRCam_2023-06-24": {
        "target_name": "SATURN-CENTRE",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F322W2;F323N",
        "obs_title": "Saturn",
        "parent_obsid": "149342764",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet",
        "file_name": "jw01247-o301_t637_nircam_f322w2-f323n_i2d.fits",
        "start_time": "2023-06-24 18:27:54.136",
        "end_time": "2023-06-24 18:36:29.476",
        "exposure_time": "0:04:17.682000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01247-o301_t637_nircam_f322w2-f323n_i2d.fits",
        "size": 120755520
    },
    "NGC-4254_NIRCam_2023-06-18": {
        "target_name": "NGC-4254",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "147760736",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o030_t012_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-06-18 06:24:31.240",
        "end_time": "2023-06-18 07:49:48.840",
        "exposure_time": "0:14:18.944000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o030_t012_nircam_clear-f360m_i2d.fits",
        "size": 250536960
    },
    "2MASS-J20452110+4223513_NIRCam_2023-05-31": {
        "target_name": "2MASS-J20452110+4223513",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "92934495",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183014001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2022-09-14 14:03:28.037",
        "end_time": "2022-09-14 14:04:00.358",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183014001_03103_00001-seg001_nrca3_calints.fits",
        "size": 52528320
    },
    "J1211-0118_NIRCam_2023-05-24": {
        "target_name": "J1211-0118",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F410M",
        "obs_title": "Anchoring z>6 Galaxy Metallicities using T_e and n_e Diagnostics Enabled by JWST and ALMA Spectroscopy",
        "parent_obsid": "109938444",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Lyman-alpha galaxies, Lyman-break galaxies",
        "file_name": "jw01657-o007_t003_nircam_clear-f410m_i2d.fits",
        "start_time": "2023-01-18 12:32:00.800",
        "end_time": "2023-01-18 12:46:30.459",
        "exposure_time": "0:10:01.260000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01657-o007_t003_nircam_clear-f410m_i2d.fits",
        "size": 132114240
    },
    "NGC-3351_NIRCam_2023-05-18": {
        "target_name": "NGC-3351",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "138571700",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o028_t010_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-05-18 17:46:36.788",
        "end_time": "2023-05-18 19:12:04.500",
        "exposure_time": "0:14:18.944000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o028_t010_nircam_clear-f360m_i2d.fits",
        "size": 249871680
    },
    "GRB230307A_NIRCam_2023-05-08": {
        "target_name": "GRB230307A",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Revealing the nature of the exceptional GRB 230307A: nearby nucleosynthesis or a primordial explosion?",
        "parent_obsid": "125218812",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Gamma Ray bursters",
        "file_name": "jw04434-o003_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-04-05 12:26:26.832",
        "end_time": "2023-04-05 12:59:33.137",
        "exposure_time": "0:25:46.092000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw04434-o003_t001_nircam_clear-f356w_i2d.fits",
        "size": 144380160
    },
    "SNH0pe-2b_NIRCam_2023-05-09": {
        "target_name": "SNH0pe-2b",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "SN H0pe: Independent Measurement of H0 by the Time Delay of a Multiply-imaged Supernova",
        "parent_obsid": "137550069",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Supernovae",
        "file_name": "jw04446-c1000_t009_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-04-22 22:44:57.961",
        "end_time": "2023-05-09 13:47:38.006",
        "exposure_time": "0:42:56.828000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw04446-c1000_t009_nircam_clear-f444w_i2d.fits",
        "size": 179222400
    },
    "NGC-2835_NIRCam_2023-05-07": {
        "target_name": "NGC-2835",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "135570317",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o027_t009_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-05-07 05:03:33.200",
        "end_time": "2023-05-07 05:24:40.180",
        "exposure_time": "0:07:09.472000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o027_t009_nircam_clear-f360m_i2d.fits",
        "size": 132125760
    },
    "WISE0855-0714_NIRCam_2023-04-27": {
        "target_name": "WISE0855-0714",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "The Physics of Brown Dwarfs - Part #3",
        "parent_obsid": "211290587",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw01230-o002_t001_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-11-19 08:18:05.982",
        "end_time": "2022-11-19 08:30:16.085",
        "exposure_time": "0:10:22.732000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01230-o002_t001_nircam_clear-f444w_i2d.fits",
        "size": 131440320
    },
    "WISE0855-0714_NIRISS_2023-04-17": {
        "target_name": "WISE0855-0714",
        "target_classification": "Star",
        "instrument_name": "NIRISS/image",
        "filters": "F480M",
        "obs_title": "The Physics of Brown Dwarfs - Part #3",
        "parent_obsid": "101247529",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw01230-o004_t001_niriss_clearp-f480m_i2d.fits",
        "start_time": "2022-11-19 08:49:28.100",
        "end_time": "2022-11-19 09:46:47.376",
        "exposure_time": "0:36:30.301000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01230-o004_t001_niriss_clearp-f480m_i2d.fits",
        "size": 118128960
    },
    "SGRA_NIRCam_2023-04-18": {
        "target_name": "SGRA",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F210M",
        "obs_title": "Imaging the Shadow of a Black Hole through Sgr A* Monitoring",
        "parent_obsid": "128095545",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Young star clusters",
        "file_name": "jw02235-o003_t001_nircam_clear-f210m-sub640_i2d.fits",
        "start_time": "2023-04-18 06:34:08.318",
        "end_time": "2023-04-18 12:44:41.893",
        "exposure_time": "5:01:22.824000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02235-o003_t001_nircam_clear-f210m-sub640_i2d.fits",
        "size": 77140800
    },
    "GJ-436_NIRCam_2023-04-21": {
        "target_name": "GJ-436",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "WLP4",
        "obs_title": "Transit Spectroscopy of Mature Planets",
        "parent_obsid": "107465145",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Exoplanet Systems, M dwarfs, M stars",
        "file_name": "jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "start_time": "2022-12-31 22:16:05.494",
        "end_time": "2023-01-01 03:03:09.120",
        "exposure_time": "4:20:57.842000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "size": 79280640
    },
    "P177D_NIRCam_2023-04-22": {
        "target_name": "P177D",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (G Dwarfs)",
        "parent_obsid": "128928810",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw01538-o069_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-04-22 06:23:26.837",
        "end_time": "2023-04-22 06:27:01.594",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01538-o069_t001_nircam_clear-f277w_i2d.fits",
        "size": 287605440
    },
    "GRB221009A-OT_NIRCam_2023-04-22": {
        "target_name": "GRB221009A-OT",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Late Time Observations of GRB 221009A: The First Search for r-Process Nucleosynthesis in a Collapsar",
        "parent_obsid": "128929605",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Gamma Ray transients, Supernovae",
        "file_name": "jw02784-o002_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-04-22 07:08:41.132",
        "end_time": "2023-04-22 07:22:27.927",
        "exposure_time": "0:09:18.312000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02784-o002_t001_nircam_clear-f277w_i2d.fits",
        "size": 120804480
    },
    "SNH0pe-2b_NIRCam_2023-04-22": {
        "target_name": "SNH0pe-2b",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "SN H0pe: Independent Measurement of H0 by the Time Delay of a Multiply-imaged Supernova",
        "parent_obsid": "137550069",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Supernovae",
        "file_name": "jw04446-c1000_t009_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-04-22 22:44:57.961",
        "end_time": "2023-05-09 13:47:38.006",
        "exposure_time": "0:42:56.828000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw04446-c1000_t009_nircam_clear-f444w_i2d.fits",
        "size": 179222400
    },
    "P177D_NIRCam_2023-04-13": {
        "target_name": "P177D",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (G Dwarfs)",
        "parent_obsid": "128928810",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw01538-o069_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-04-22 06:23:26.837",
        "end_time": "2023-04-22 06:27:01.594",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01538-o069_t001_nircam_clear-f277w_i2d.fits",
        "size": 287605440
    },
    "SGRA_NIRCam_2023-04-13": {
        "target_name": "SGRA",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F210M",
        "obs_title": "Imaging the Shadow of a Black Hole through Sgr A* Monitoring",
        "parent_obsid": "128095545",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Young star clusters",
        "file_name": "jw02235-o003_t001_nircam_clear-f210m-sub640_i2d.fits",
        "start_time": "2023-04-18 06:34:08.318",
        "end_time": "2023-04-18 12:44:41.893",
        "exposure_time": "5:01:22.824000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02235-o003_t001_nircam_clear-f210m-sub640_i2d.fits",
        "size": 77140800
    },
    "SGRA_NIRCam_2023-04-16": {
        "target_name": "SGRA",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F210M",
        "obs_title": "Imaging the Shadow of a Black Hole through Sgr A* Monitoring",
        "parent_obsid": "128095545",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Young star clusters",
        "file_name": "jw02235-o003_t001_nircam_clear-f210m-sub640_i2d.fits",
        "start_time": "2023-04-18 06:34:08.318",
        "end_time": "2023-04-18 12:44:41.893",
        "exposure_time": "5:01:22.824000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02235-o003_t001_nircam_clear-f210m-sub640_i2d.fits",
        "size": 77140800
    },
    "GRB230307A_NIRCam_2023-04-05": {
        "target_name": "GRB230307A",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Revealing the nature of the exceptional GRB 230307A: nearby nucleosynthesis or a primordial explosion?",
        "parent_obsid": "125218812",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Gamma Ray bursters",
        "file_name": "jw04434-o003_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-04-05 12:26:26.832",
        "end_time": "2023-04-05 12:59:33.137",
        "exposure_time": "0:25:46.092000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw04434-o003_t001_nircam_clear-f356w_i2d.fits",
        "size": 144380160
    },
    "MARS_NIRCam_2023-03-22": {
        "target_name": "MARS",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "A sensitive search for rings and small moons in the Martian system using JWST",
        "parent_obsid": "158406166",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet, Ring, Satellite",
        "file_name": "jw02787-c1002_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-03-22 22:08:00.958",
        "end_time": "2023-03-23 00:48:02.393",
        "exposure_time": "0:22:54.304000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02787-c1002_t001_nircam_clear-f277w_i2d.fits",
        "size": 123773760
    },
    "MARS_NIRCam_2023-03-23": {
        "target_name": "MARS",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "A sensitive search for rings and small moons in the Martian system using JWST",
        "parent_obsid": "158406166",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet, Ring, Satellite",
        "file_name": "jw02787-c1002_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-03-22 22:08:00.958",
        "end_time": "2023-03-23 00:48:02.393",
        "exposure_time": "0:22:54.304000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02787-c1002_t001_nircam_clear-f277w_i2d.fits",
        "size": 123773760
    },
    "MARS_NIRCam_2023-03-25": {
        "target_name": "MARS",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "A sensitive search for rings and small moons in the Martian system using JWST",
        "parent_obsid": "158406166",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet, Ring, Satellite",
        "file_name": "jw02787-c1002_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-03-22 22:08:00.958",
        "end_time": "2023-03-23 00:48:02.393",
        "exposure_time": "0:22:54.304000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02787-c1002_t001_nircam_clear-f277w_i2d.fits",
        "size": 123773760
    },
    "TYC-7380-1046-1_NIRCam_2023-03-17": {
        "target_name": "TYC-7380-1046-1",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "93519269",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183020001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2022-09-19 13:46:30.198",
        "end_time": "2022-09-19 13:46:46.421",
        "exposure_time": "0:00:13.519000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183020001_03103_00001-seg001_nrca3_calints.fits",
        "size": 26323200
    },
    "P177D_NIRCam_2023-03-19": {
        "target_name": "P177D",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (G Dwarfs)",
        "parent_obsid": "128928810",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw01538-o069_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-04-22 06:23:26.837",
        "end_time": "2023-04-22 06:27:01.594",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01538-o069_t001_nircam_clear-f277w_i2d.fits",
        "size": 287605440
    },
    "GALCEN_NIRCam_2023-03-17": {
        "target_name": "GALCEN",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F322W2;F323N",
        "obs_title": "The Galactic Center",
        "parent_obsid": "93557305",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Stellar associations, Young star clusters",
        "file_name": "jw01939-o001_t001_nircam_f322w2-f323n_i2d.fits",
        "start_time": "2022-09-19 14:20:28.834",
        "end_time": "2022-09-19 15:21:30.127",
        "exposure_time": "0:42:56.820000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01939-o001_t001_nircam_f322w2-f323n_i2d.fits",
        "size": 307221120
    },
    "2MASS-J16313124-2426281_NIRCam_2023-03-07": {
        "target_name": "2MASS-J16313124-2426281",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "Dust Settling and Grain Evolution in Edge-on Protoplanetary Disks",
        "parent_obsid": "118338405",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Circumstellar disks, Pre-main sequence stars, Protoplanetary disks, Young stellar objects",
        "file_name": "jw02562-o007_t005_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-03-07 19:56:24.944",
        "end_time": "2023-03-07 20:15:12.303",
        "exposure_time": "0:12:53.048000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02562-o007_t005_nircam_clear-f444w_i2d.fits",
        "size": 120804480
    },
    "OPH-CORE_NIRCam_2023-03-07": {
        "target_name": "OPH-CORE",
        "target_classification": "ISM",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F470N",
        "obs_title": "JWST Cycle 1 Outreach Campaign",
        "parent_obsid": "125116351",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dense interstellar clouds",
        "file_name": "jw02739-o010_t007_nircam_f444w-f470n_i2d.fits",
        "start_time": "2023-04-05 02:57:16.947",
        "end_time": "2023-04-05 03:28:46.963",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02739-o010_t007_nircam_f444w-f470n_i2d.fits",
        "size": 360636480
    },
    "OPH-CORE_NIRCam_2023-03-08": {
        "target_name": "OPH-CORE",
        "target_classification": "ISM",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F470N",
        "obs_title": "JWST Cycle 1 Outreach Campaign",
        "parent_obsid": "125116351",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dense interstellar clouds",
        "file_name": "jw02739-o010_t007_nircam_f444w-f470n_i2d.fits",
        "start_time": "2023-04-05 02:57:16.947",
        "end_time": "2023-04-05 03:28:46.963",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02739-o010_t007_nircam_f444w-f470n_i2d.fits",
        "size": 360636480
    },
    "RM275_NIRCam_2023-03-09": {
        "target_name": "RM275",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F090W",
        "obs_title": "A JWST Study of the Link Between Supermassive Black Holes and Galaxies at Cosmic Noon",
        "parent_obsid": "118346403",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw02057-o005_t003_nircam_clear-f090w-sub640_i2d.fits",
        "start_time": "2023-03-09 07:43:31.359",
        "end_time": "2023-03-09 07:55:43.141",
        "exposure_time": "0:08:05.556000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02057-o005_t003_nircam_clear-f090w-sub640_i2d.fits",
        "size": 58613760
    },
    "RM312_NIRCam_2023-03-09": {
        "target_name": "RM312",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F090W",
        "obs_title": "A JWST Study of the Link Between Supermassive Black Holes and Galaxies at Cosmic Noon",
        "parent_obsid": "118346409",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw02057-o007_t004_nircam_clear-f090w-sub640_i2d.fits",
        "start_time": "2023-03-09 08:35:18.620",
        "end_time": "2023-03-09 08:47:28.482",
        "exposure_time": "0:08:05.556000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02057-o007_t004_nircam_clear-f090w-sub640_i2d.fits",
        "size": 58613760
    },
    "J1425-0015_NIRCam_2023-02-27": {
        "target_name": "J1425-0015",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A Complete Census of Supermassive Black Holes and Host Galaxies at z=6",
        "parent_obsid": "118008160",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw01967-o020_t010_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-02-27 00:33:17.380",
        "end_time": "2023-02-27 01:48:59.087",
        "exposure_time": "0:51:32.192000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01967-o020_t010_nircam_clear-f356w_i2d.fits",
        "size": 132523200
    },
    "WISE-1206+84_NIRCam_2023-02-27": {
        "target_name": "WISE-1206+84",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "118051355",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw02473-o064_t013_nircam_clear-f480m_i2d.fits",
        "start_time": "2023-02-27 20:51:18.354",
        "end_time": "2023-02-27 22:12:11.327",
        "exposure_time": "1:11:34.710000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o064_t013_nircam_clear-f480m_i2d.fits",
        "size": 137903040
    },
    "NGC-5068_NIRCam_2023-02-28": {
        "target_name": "NGC-5068",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "118119213",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o034_t016_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-02-28 20:51:45.418",
        "end_time": "2023-02-28 22:17:02.698",
        "exposure_time": "0:14:18.944000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o034_t016_nircam_clear-f360m_i2d.fits",
        "size": 261100800
    },
    "MACSJ1423.8+2404_NIRISS_2023-03-01": {
        "target_name": "MACSJ1423.8+2404",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F115W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "183629007",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o010_t005_niriss_clear-f115w_i2d.fits",
        "start_time": "2023-02-26 12:26:55.412",
        "end_time": "2023-02-26 16:38:09.711",
        "exposure_time": "0:22:54.308000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o010_t005_niriss_clear-f115w_i2d.fits",
        "size": 133983360
    },
    "NGC-2024_NIRCam_2023-03-01": {
        "target_name": "NGC-2024",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "Embedded Cluster Survey",
        "parent_obsid": "118307986",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Open star clusters, Young star clusters",
        "file_name": "jw01190-c1001_t001_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-03-01 08:55:30.018",
        "end_time": "2023-03-01 13:01:06.735",
        "exposure_time": "1:53:48.583000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01190-c1001_t001_nircam_clear-f360m_i2d.fits",
        "size": 288034560
    },
    "WISE-1446-23_NIRCam_2023-03-02": {
        "target_name": "WISE-1446-23",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "118185760",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Brown dwarfs, Y dwarfs",
        "file_name": "jw02473-o066_t055_nircam_clear-f480m_i2d.fits",
        "start_time": "2023-03-02 07:58:24.699",
        "end_time": "2023-03-02 10:31:35.395",
        "exposure_time": "2:23:09.415000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o066_t055_nircam_clear-f480m_i2d.fits",
        "size": 137903040
    },
    "WISE-1541-22_NIRCam_2023-03-02": {
        "target_name": "WISE-1541-22",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "118185756",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw02473-o067_t017_nircam_clear-f480m_i2d.fits",
        "start_time": "2023-03-02 10:59:28.133",
        "end_time": "2023-03-02 11:36:41.439",
        "exposure_time": "0:28:37.885000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o067_t017_nircam_clear-f480m_i2d.fits",
        "size": 137842560
    },
    "WISE-1405+55_NIRCam_2023-03-02": {
        "target_name": "WISE-1405+55",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "118185758",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "O dwarfs, Y dwarfs",
        "file_name": "jw02473-o065_t015_nircam_clear-f480m_i2d.fits",
        "start_time": "2023-03-02 12:26:57.603",
        "end_time": "2023-03-02 13:00:14.682",
        "exposure_time": "0:25:03.150000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o065_t015_nircam_clear-f480m_i2d.fits",
        "size": 137903040
    },
    "RM052_NIRCam_2023-03-02": {
        "target_name": "RM052",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F150W",
        "obs_title": "A JWST Study of the Link Between Supermassive Black Holes and Galaxies at Cosmic Noon",
        "parent_obsid": "118203002",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw02057-o003_t002_nircam_clear-f150w-sub640_i2d.fits",
        "start_time": "2023-03-02 13:45:46.578",
        "end_time": "2023-03-02 13:55:08.567",
        "exposure_time": "0:05:18.124000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02057-o003_t002_nircam_clear-f150w-sub640_i2d.fits",
        "size": 58613760
    },
    "GSC-08152-02121_NIRCam_2023-03-02": {
        "target_name": "GSC-08152-02121",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "117962685",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183033001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2023-02-25 00:59:28.317",
        "end_time": "2023-02-25 01:00:00.638",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183033001_03103_00001-seg001_nrca3_calints.fits",
        "size": 52528320
    },
    "J1526M2050_NIRCam_2023-03-03": {
        "target_name": "J1526M2050",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "118224856",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o071_t025_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-03-03 11:15:17.368",
        "end_time": "2023-03-03 11:45:21.290",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o071_t025_nircam_clear-f356w_i2d.fits",
        "size": 320477760
    },
    "NGC5236-NIRCAM_NIRCam_2023-03-06": {
        "target_name": "NGC5236-NIRCAM",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F300M",
        "obs_title": "JWST probes Feedback in Emerging extrAgalactic Star clusTers: JWST-FEAST",
        "parent_obsid": "118308527",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw01783-o001_t001_nircam_clear-f300m_i2d.fits",
        "start_time": "2023-03-06 05:52:17.024",
        "end_time": "2023-03-06 06:16:16.068",
        "exposure_time": "0:10:01.256000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01783-o001_t001_nircam_clear-f300m_i2d.fits",
        "size": 349827840
    },
    "IRAS-04302+2247_NIRCam_2023-02-20": {
        "target_name": "IRAS-04302+2247",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "Dust Settling and Grain Evolution in Edge-on Protoplanetary Disks",
        "parent_obsid": "117897018",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Circumstellar disks, Herbig-Haro objects, Pre-main sequence stars, Protoplanetary disks, Young stellar objects",
        "file_name": "jw02562-o003_t002_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-02-20 01:20:37.066",
        "end_time": "2023-02-20 01:39:56.602",
        "exposure_time": "0:14:18.940000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02562-o003_t002_nircam_clear-f444w_i2d.fits",
        "size": 120764160
    },
    "N5584_NIRCam_2023-02-21": {
        "target_name": "N5584",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Uncrowding the Cepheids for an Improved Determination of the Hubble Constant",
        "parent_obsid": "117935154",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral arms",
        "file_name": "jw01685-c1009_t005_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-01-30 17:46:17.026",
        "end_time": "2023-02-21 22:16:44.817",
        "exposure_time": "2:05:58.688000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01685-c1009_t005_nircam_clear-f277w_i2d.fits",
        "size": 351221760
    },
    "CPD-59-5831_NIRCam_2023-02-22": {
        "target_name": "CPD-59-5831",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "117935720",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183039001_03103_00001-seg001_nrca1_calints.fits",
        "start_time": "2023-02-22 02:31:57.219",
        "end_time": "2023-02-22 02:32:13.442",
        "exposure_time": "0:00:13.519000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183039001_03103_00001-seg001_nrca1_calints.fits",
        "size": 26323200
    },
    "GSC-08152-02121_NIRCam_2023-02-25": {
        "target_name": "GSC-08152-02121",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "117962685",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183033001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2023-02-25 00:59:28.317",
        "end_time": "2023-02-25 01:00:00.638",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183033001_03103_00001-seg001_nrca3_calints.fits",
        "size": 52528320
    },
    "MACSJ1423.8+2404_NIRISS_2023-02-25": {
        "target_name": "MACSJ1423.8+2404",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F115W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "183629007",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o010_t005_niriss_clear-f115w_i2d.fits",
        "start_time": "2023-02-26 12:26:55.412",
        "end_time": "2023-02-26 16:38:09.711",
        "exposure_time": "0:22:54.308000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o010_t005_niriss_clear-f115w_i2d.fits",
        "size": 133983360
    },
    "MACSJ1423.8+2404_NIRCam_2023-02-26": {
        "target_name": "MACSJ1423.8+2404",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "182395928",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o010_t005_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-02-26 14:35:15.915",
        "end_time": "2023-02-26 16:38:33.583",
        "exposure_time": "1:46:39.116000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o010_t005_nircam_clear-f356w_i2d.fits",
        "size": 344041920
    },
    "MACSJ1423.8+2404_NIRISS_2023-02-26": {
        "target_name": "MACSJ1423.8+2404",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F115W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "183629007",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o010_t005_niriss_clear-f115w_i2d.fits",
        "start_time": "2023-02-26 12:26:55.412",
        "end_time": "2023-02-26 16:38:09.711",
        "exposure_time": "0:22:54.308000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o010_t005_niriss_clear-f115w_i2d.fits",
        "size": 133983360
    },
    "A1689-ZD1_NIRCam_2023-02-15": {
        "target_name": "A1689-ZD1",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "ALMA [OIII]88um Emitters. Signpost of Early Stellar Buildup and Reionization in the Universe",
        "parent_obsid": "116748307",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies",
        "file_name": "jw01840-o019_t009_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-02-15 03:57:09.208",
        "end_time": "2023-02-15 04:19:09.850",
        "exposure_time": "0:17:10.728000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01840-o019_t009_nircam_clear-f277w_i2d.fits",
        "size": 132114240
    },
    "J1342+0928_NIRCam_2023-02-16": {
        "target_name": "J1342+0928",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F430M",
        "obs_title": "A Comprehensive JWST View of the Most Distant Quasars Deep Into the Epoch of Reionization",
        "parent_obsid": "117062952",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw01764-o017_t003_nircam_clear-f430m_i2d.fits",
        "start_time": "2023-02-17 00:11:14.037",
        "end_time": "2023-02-17 01:10:06.505",
        "exposure_time": "0:46:42.294000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01764-o017_t003_nircam_clear-f430m_i2d.fits",
        "size": 325221120
    },
    "URANUS_NIRCam_2023-02-06": {
        "target_name": "URANUS",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F460M",
        "obs_title": "JWST Cycle 1 Outreach Campaign",
        "parent_obsid": "173671567",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet",
        "file_name": "jw02739-o011_t002_nircam_clear-f460m_i2d.fits",
        "start_time": "2023-09-04 22:34:05.732",
        "end_time": "2023-09-05 00:15:33.525",
        "exposure_time": "0:30:25.250000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02739-o011_t002_nircam_clear-f460m_i2d.fits",
        "size": 137859840
    },
    "NGC-4535_NIRCam_2023-02-08": {
        "target_name": "NGC-4535",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "114767376",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o033_t015_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-02-08 05:12:09.657",
        "end_time": "2023-02-08 05:33:05.885",
        "exposure_time": "0:07:09.472000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o033_t015_nircam_clear-f360m_i2d.fits",
        "size": 132125760
    },
    "NGC4536_NIRCam_2023-02-08": {
        "target_name": "NGC4536",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "Answering the Most Important Problem in Cosmology Today: Is the Tension in the Hubble Constant Real?",
        "parent_obsid": "119424684",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Galaxy disks, Galaxy halos",
        "file_name": "jw01995-o021_t020_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-02-08 08:22:21.659",
        "end_time": "2023-02-08 09:21:03.375",
        "exposure_time": "0:46:42.294000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01995-o021_t020_nircam_clear-f444w_i2d.fits",
        "size": 308001600
    },
    "J1241+2219-B_NIRCam_2023-02-08": {
        "target_name": "J1241+2219-B",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F250M",
        "obs_title": "Characterizing Stellar Mass Assembly and Physical Properties in the Brightest Galaxy in the Redshift>5 Universe",
        "parent_obsid": "114802195",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Elliptical galaxies, Emission line galaxies, High-redshift galaxies, Lyman-break galaxies",
        "file_name": "jw02566-o004_t002_nircam_clear-f250m_i2d.fits",
        "start_time": "2023-02-08 09:55:41.261",
        "end_time": "2023-02-08 10:14:28.620",
        "exposure_time": "0:14:18.940000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02566-o004_t002_nircam_clear-f250m_i2d.fits",
        "size": 132114240
    },
    "NGC-1672_NIRCam_2023-02-09": {
        "target_name": "NGC-1672",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "114803325",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o026_t008_nircam_clear-f335m_i2d.fits",
        "start_time": "2023-02-09 01:08:58.587",
        "end_time": "2023-02-09 02:52:54.610",
        "exposure_time": "0:12:53.048000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o026_t008_nircam_clear-f335m_i2d.fits",
        "size": 250061760
    },
    "2MASS-J17430448+6655015_NIRISS_2023-02-09": {
        "target_name": "2MASS-J17430448+6655015",
        "target_classification": "Star",
        "instrument_name": "NIRISS/image",
        "filters": "F480M",
        "obs_title": "AMI Full Frame Fractional Throughput",
        "parent_obsid": "114805633",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "A dwarfs",
        "file_name": "jw01506-o001_t001_niriss_clearp-f480m_i2d.fits",
        "start_time": "2023-02-09 10:44:04.102",
        "end_time": "2023-02-09 10:54:57.261",
        "exposure_time": "0:05:43.576000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01506-o001_t001_niriss_clearp-f480m_i2d.fits",
        "size": 118944000
    },
    "MACSJ0416.1-2403_NIRCam_2023-02-10": {
        "target_name": "MACSJ0416.1-2403",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "JWST Medium-Deep Fields -- Windhorst IDS GTO Program",
        "parent_obsid": "182417942",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o004_t002_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-11 22:40:08.848",
        "end_time": "2023-01-12 00:43:15.892",
        "exposure_time": "1:46:39.116000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o004_t002_nircam_clear-f356w_i2d.fits",
        "size": 344041920
    },
    "HORSEHEAD-FILAMENT-IMAGING_NIRCam_2023-01-30": {
        "target_name": "HORSEHEAD-FILAMENT-IMAGING",
        "target_classification": "ISM",
        "instrument_name": "NIRCam/image",
        "filters": "F322W2;F323N",
        "obs_title": "Physics and Chemistry of PDR Fronts",
        "parent_obsid": "112510979",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Photodissociation regions, Reflection nebulae",
        "file_name": "jw01192-o015_t015_nircam_f322w2-f323n_i2d.fits",
        "start_time": "2023-01-30 07:03:30.764",
        "end_time": "2023-01-30 07:32:51.601",
        "exposure_time": "0:26:18.306000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01192-o015_t015_nircam_f322w2-f323n_i2d.fits",
        "size": 122091840
    },
    "NGC4258-HALO_NIRCam_2023-01-30": {
        "target_name": "NGC4258-HALO",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "Answering the Most Important Problem in Cosmology Today: Is the Tension in the Hubble Constant Real?",
        "parent_obsid": "112774571",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Field galaxies, Galaxy disks, Galaxy halos",
        "file_name": "jw01995-o003_t003_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-01-30 16:01:43.664",
        "end_time": "2023-01-30 17:00:36.132",
        "exposure_time": "0:46:42.294000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01995-o003_t003_nircam_clear-f444w_i2d.fits",
        "size": 308067840
    },
    "N5584_NIRCam_2023-01-30": {
        "target_name": "N5584",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Uncrowding the Cepheids for an Improved Determination of the Hubble Constant",
        "parent_obsid": "117935154",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral arms",
        "file_name": "jw01685-c1009_t005_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-01-30 17:46:17.026",
        "end_time": "2023-02-21 22:16:44.817",
        "exposure_time": "2:05:58.688000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01685-c1009_t005_nircam_clear-f277w_i2d.fits",
        "size": 351221760
    },
    "NGC-4303_NIRCam_2023-01-31": {
        "target_name": "NGC-4303",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "113463303",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o031_t013_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-01-31 18:13:01.286",
        "end_time": "2023-01-31 18:34:08.266",
        "exposure_time": "0:07:09.472000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o031_t013_nircam_clear-f360m_i2d.fits",
        "size": 132125760
    },
    "HUDF-DEEP-F160W_NIRISS_2023-02-01": {
        "target_name": "HUDF-DEEP-F160W",
        "target_classification": "Galaxy",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F200W",
        "obs_title": "The Next Generation Deep Extragalactic Exploratory Public (NGDEEP) Survey: Feedback in Low-Mass Galaxies from Cosmic Dawn to Dusk",
        "parent_obsid": "113644309",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Emission line galaxies, High-redshift galaxies, Lyman-alpha galaxies, Lyman-break galaxies",
        "file_name": "jw02079-o004_t001_niriss_clear-f200w_i2d.fits",
        "start_time": "2023-02-01 15:44:31.167",
        "end_time": "2023-02-02 01:21:37.554",
        "exposure_time": "0:28:37.880000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02079-o004_t001_niriss_clear-f200w_i2d.fits",
        "size": 138075840
    },
    "HUDF-DEEP-F160W_NIRISS_2023-02-02": {
        "target_name": "HUDF-DEEP-F160W",
        "target_classification": "Galaxy",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F200W",
        "obs_title": "The Next Generation Deep Extragalactic Exploratory Public (NGDEEP) Survey: Feedback in Low-Mass Galaxies from Cosmic Dawn to Dusk",
        "parent_obsid": "113644309",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Emission line galaxies, High-redshift galaxies, Lyman-alpha galaxies, Lyman-break galaxies",
        "file_name": "jw02079-o004_t001_niriss_clear-f200w_i2d.fits",
        "start_time": "2023-02-01 15:44:31.167",
        "end_time": "2023-02-02 01:21:37.554",
        "exposure_time": "0:28:37.880000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02079-o004_t001_niriss_clear-f200w_i2d.fits",
        "size": 138075840
    },
    "NGC-1512_NIRCam_2023-02-04": {
        "target_name": "NGC-1512",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "113938141",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o024_t006_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-02-04 08:21:41.407",
        "end_time": "2023-02-04 09:47:11.359",
        "exposure_time": "0:14:18.944000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o024_t006_nircam_clear-f360m_i2d.fits",
        "size": 249972480
    },
    "NAME-ZTF-J153932.16+502738.8_NIRCam_2023-02-05": {
        "target_name": "NAME-ZTF-J153932.16+502738.8",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Validating JWSTs in-orbit clock accuracy",
        "parent_obsid": "114180752",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Compact binary systems, White dwarfs",
        "file_name": "jw01666001001_02102_00001-seg003_nrcblong_calints.fits",
        "start_time": "2023-02-05 19:02:07.281",
        "end_time": "2023-02-06 04:28:41.361",
        "exposure_time": "8:58:14.376000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw01666001001_02102_00001-seg003_nrcblong_calints.fits",
        "size": 153809280
    },
    "NGC-1087_NIRCam_2023-01-25": {
        "target_name": "NGC-1087",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F300M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "111809869",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o019_t001_nircam_clear-f300m_i2d.fits",
        "start_time": "2023-01-25 13:26:30.910",
        "end_time": "2023-01-25 14:03:03.348",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o019_t001_nircam_clear-f300m_i2d.fits",
        "size": 132171840
    },
    "NGC-1300_NIRCam_2023-01-25": {
        "target_name": "NGC-1300",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "111813698",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o020_t002_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-01-25 18:33:11.679",
        "end_time": "2023-01-25 19:58:30.111",
        "exposure_time": "0:14:18.944000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o020_t002_nircam_clear-f360m_i2d.fits",
        "size": 249871680
    },
    "NGC-1300_NIRCam_2023-01-26": {
        "target_name": "NGC-1300",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "111813698",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o020_t002_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-01-25 18:33:11.679",
        "end_time": "2023-01-25 19:58:30.111",
        "exposure_time": "0:14:18.944000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o020_t002_nircam_clear-f360m_i2d.fits",
        "size": 249871680
    },
    "NGC628-NIRCAM_NIRCam_2023-01-16": {
        "target_name": "NGC628-NIRCAM",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "JWST probes Feedback in Emerging extrAgalactic Star clusTers: JWST-FEAST",
        "parent_obsid": "87767066",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw01783-o004_t008_nircam_clear-f335m_i2d.fits",
        "start_time": "2022-07-29 06:30:09.483",
        "end_time": "2022-07-29 09:11:42.893",
        "exposure_time": "0:48:40.400000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01783-o004_t008_nircam_clear-f335m_i2d.fits",
        "size": 349646400
    },
    "GJ-436_NIRCam_2023-01-16": {
        "target_name": "GJ-436",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "WLP4",
        "obs_title": "Transit Spectroscopy of Mature Planets",
        "parent_obsid": "107465145",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Exoplanet Systems, M dwarfs, M stars",
        "file_name": "jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "start_time": "2022-12-31 22:16:05.494",
        "end_time": "2023-01-01 03:03:09.120",
        "exposure_time": "4:20:57.842000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "size": 79280640
    },
    "J1120+0641_NIRCam_2023-01-17": {
        "target_name": "J1120+0641",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Exploring the End of Cosmic Reionization",
        "parent_obsid": "109767044",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw01243-o009_t004_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-17 00:21:01.641",
        "end_time": "2023-01-17 00:51:19.053",
        "exposure_time": "0:17:32.204000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01243-o009_t004_nircam_clear-f356w_i2d.fits",
        "size": 398666880
    },
    "NGC-4321_NIRCam_2023-01-17": {
        "target_name": "NGC-4321",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "109838029",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o032_t014_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-01-17 10:50:25.669",
        "end_time": "2023-01-17 11:11:32.649",
        "exposure_time": "0:07:09.472000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o032_t014_nircam_clear-f360m_i2d.fits",
        "size": 132186240
    },
    "DIGELC2-S_NIRCam_2023-01-17": {
        "target_name": "DIGELC2-S",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Star Formation in the Extreme Outer Galaxy",
        "parent_obsid": "109850887",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Young star clusters",
        "file_name": "jw01237-o001_t002_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-17 17:02:04.028",
        "end_time": "2023-01-17 18:08:48.913",
        "exposure_time": "0:14:18.944000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01237-o001_t002_nircam_clear-f356w_i2d.fits",
        "size": 191923200
    },
    "DIGELC2-N_NIRCam_2023-01-17": {
        "target_name": "DIGELC2-N",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Star Formation in the Extreme Outer Galaxy",
        "parent_obsid": "109850801",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Young star clusters",
        "file_name": "jw01237-o002_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-17 19:05:59.909",
        "end_time": "2023-01-17 19:16:44.109",
        "exposure_time": "0:06:26.524000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01237-o002_t001_nircam_clear-f356w_i2d.fits",
        "size": 120804480
    },
    "ANDROMEDA-XVII_NIRCam_2023-01-18": {
        "target_name": "ANDROMEDA-XVII",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Dynamics of the Andromeda Galaxy Satellite System",
        "parent_obsid": "87766841",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf galaxies, Dwarf spheroidal galaxies",
        "file_name": "jw01305-o004_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-07-29 00:55:48.498",
        "end_time": "2022-07-29 01:27:50.472",
        "exposure_time": "0:25:56.830000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01305-o004_t004_nircam_clear-f277w_i2d.fits",
        "size": 288276480
    },
    "ANDROMEDA-I_NIRCam_2023-01-18": {
        "target_name": "ANDROMEDA-I",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Dynamics of the Andromeda Galaxy Satellite System",
        "parent_obsid": "87770087",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf galaxies, Dwarf spheroidal galaxies",
        "file_name": "jw01305-o001_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-07-28 21:57:00.129",
        "end_time": "2022-07-28 22:29:02.034",
        "exposure_time": "0:25:56.830000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01305-o001_t001_nircam_clear-f277w_i2d.fits",
        "size": 288276480
    },
    "J1211-0118_NIRCam_2023-01-18": {
        "target_name": "J1211-0118",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F410M",
        "obs_title": "Anchoring z>6 Galaxy Metallicities using T_e and n_e Diagnostics Enabled by JWST and ALMA Spectroscopy",
        "parent_obsid": "109938444",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies",
        "file_name": "jw01657-o007_t003_nircam_clear-f410m_i2d.fits",
        "start_time": "2023-01-18 12:32:00.800",
        "end_time": "2023-01-18 12:46:30.459",
        "exposure_time": "0:10:01.260000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01657-o007_t003_nircam_clear-f410m_i2d.fits",
        "size": 132114240
    },
    "NGC-1433_NIRCam_2023-01-19": {
        "target_name": "NGC-1433",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "110103178",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o023_t005_nircam_clear-f360m_i2d.fits",
        "start_time": "2023-01-19 06:18:51.800",
        "end_time": "2023-01-19 07:44:20.344",
        "exposure_time": "0:14:18.944000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o023_t005_nircam_clear-f360m_i2d.fits",
        "size": 249972480
    },
    "NGC628-NIRCAM_NIRCam_2023-01-22": {
        "target_name": "NGC628-NIRCAM",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "JWST probes Feedback in Emerging extrAgalactic Star clusTers: JWST-FEAST",
        "parent_obsid": "87767066",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw01783-o004_t008_nircam_clear-f335m_i2d.fits",
        "start_time": "2022-07-29 06:30:09.483",
        "end_time": "2022-07-29 09:11:42.893",
        "exposure_time": "0:48:40.400000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01783-o004_t008_nircam_clear-f335m_i2d.fits",
        "size": 349646400
    },
    "2MASS-J04202144+2813491_NIRCam_2023-01-23": {
        "target_name": "2MASS-J04202144+2813491",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "Dust Settling and Grain Evolution in Edge-on Protoplanetary Disks",
        "parent_obsid": "111184681",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Circumstellar disks, Herbig-Haro objects, Pre-main sequence stars, Protoplanetary disks, Young stellar objects",
        "file_name": "jw02562-o001_t001_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-01-23 06:37:24.622",
        "end_time": "2023-01-23 07:04:47.381",
        "exposure_time": "0:21:28.412000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02562-o001_t001_nircam_clear-f444w_i2d.fits",
        "size": 120764160
    },
    "DIGELC1-A_NIRCam_2023-01-10": {
        "target_name": "DIGELC1-A",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Star Formation in the Extreme Outer Galaxy",
        "parent_obsid": "108386708",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Young star clusters",
        "file_name": "jw01237-o004_t003_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-10 15:06:51.195",
        "end_time": "2023-01-10 15:16:52.465",
        "exposure_time": "0:05:43.576000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01237-o004_t003_nircam_clear-f356w_i2d.fits",
        "size": 120764160
    },
    "ANDROMEDA-III_NIRCam_2023-01-10": {
        "target_name": "ANDROMEDA-III",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Dynamics of the Andromeda Galaxy Satellite System",
        "parent_obsid": "87766220",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf galaxies, Dwarf spheroidal galaxies",
        "file_name": "jw01305-o002_t002_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-07-28 22:57:44.601",
        "end_time": "2022-07-28 23:29:46.511",
        "exposure_time": "0:25:56.830000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01305-o002_t002_nircam_clear-f277w_i2d.fits",
        "size": 288276480
    },
    "GJ-436_NIRCam_2023-01-11": {
        "target_name": "GJ-436",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "WLP4",
        "obs_title": "Transit Spectroscopy of Mature Planets",
        "parent_obsid": "107465145",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Exoplanet Systems, M dwarfs, M stars",
        "file_name": "jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "start_time": "2022-12-31 22:16:05.494",
        "end_time": "2023-01-01 03:03:09.120",
        "exposure_time": "4:20:57.842000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "size": 79280640
    },
    "MACSJ0416.1-2403_NIRCam_2023-01-11": {
        "target_name": "MACSJ0416.1-2403",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "JWST Medium-Deep Fields -- Windhorst IDS GTO Program",
        "parent_obsid": "182417942",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o004_t002_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-11 22:40:08.848",
        "end_time": "2023-01-12 00:43:15.892",
        "exposure_time": "1:46:39.116000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o004_t002_nircam_clear-f356w_i2d.fits",
        "size": 344041920
    },
    "NGC-3627_NIRCam_2023-01-13": {
        "target_name": "NGC-3627",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F300M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "109507960",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o029_t011_nircam_clear-f300m_i2d.fits",
        "start_time": "2023-01-13 14:50:32.626",
        "end_time": "2023-01-13 16:05:26.165",
        "exposure_time": "0:12:53.048000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o029_t011_nircam_clear-f300m_i2d.fits",
        "size": 256271040
    },
    "CLG-J1212+2733_NIRCam_2023-01-13": {
        "target_name": "CLG-J1212+2733",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "JWST Medium-Deep Fields -- Windhorst IDS GTO Program",
        "parent_obsid": "109188376",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01176-o271_t015_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-13 23:21:00.328",
        "end_time": "2023-01-13 23:57:19.930",
        "exposure_time": "0:31:29.672000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01176-o271_t015_nircam_clear-f356w_i2d.fits",
        "size": 306550080
    },
    "J0148+0600_NIRCam_2023-01-14": {
        "target_name": "J0148+0600",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Exploring the End of Cosmic Reionization",
        "parent_obsid": "104173840",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw01243-o011_t006_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-12-13 15:39:35.636",
        "end_time": "2022-12-13 16:08:36.825",
        "exposure_time": "0:17:32.204000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01243-o011_t006_nircam_clear-f356w_i2d.fits",
        "size": 398223360
    },
    "J0148+0600_NIRCam_2023-01-15": {
        "target_name": "J0148+0600",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Exploring the End of Cosmic Reionization",
        "parent_obsid": "104173840",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw01243-o011_t006_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-12-13 15:39:35.636",
        "end_time": "2022-12-13 16:08:36.825",
        "exposure_time": "0:17:32.204000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01243-o011_t006_nircam_clear-f356w_i2d.fits",
        "size": 398223360
    },
    "MACSJ0416.1-2403_NIRISS_2023-01-04": {
        "target_name": "MACSJ0416.1-2403",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F115W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "183624080",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o004_t002_niriss_clear-f115w_i2d.fits",
        "start_time": "2023-01-11 20:31:47.513",
        "end_time": "2023-01-12 00:42:55.092",
        "exposure_time": "0:22:54.308000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o004_t002_niriss_clear-f115w_i2d.fits",
        "size": 133983360
    },
    "CWEBTILE-3-4_NIRCam_2023-01-05": {
        "target_name": "CWEBTILE-3-4",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "COSMOS-Web: The JWST Cosmic Origins Survey",
        "parent_obsid": "107907756",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Field galaxies",
        "file_name": "jw01727-o048_t036_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-01-05 20:00:49.319",
        "end_time": "2023-01-05 20:26:24.745",
        "exposure_time": "0:17:10.728000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01727-o048_t036_nircam_clear-f444w_i2d.fits",
        "size": 349067520
    },
    "CWEBTILE-2-3_NIRCam_2023-01-05": {
        "target_name": "CWEBTILE-2-3",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "COSMOS-Web: The JWST Cosmic Origins Survey",
        "parent_obsid": "107907743",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Field galaxies",
        "file_name": "jw01727-o045_t027_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-01-05 20:43:17.477",
        "end_time": "2023-01-05 21:08:42.087",
        "exposure_time": "0:17:10.728000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01727-o045_t027_nircam_clear-f277w_i2d.fits",
        "size": 349067520
    },
    "CWEBTILE-3-3_NIRCam_2023-01-05": {
        "target_name": "CWEBTILE-3-3",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "COSMOS-Web: The JWST Cosmic Origins Survey",
        "parent_obsid": "107907727",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Field galaxies",
        "file_name": "jw01727-o046_t028_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-01-05 22:35:11.262",
        "end_time": "2023-01-05 23:00:35.936",
        "exposure_time": "0:17:10.728000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01727-o046_t028_nircam_clear-f444w_i2d.fits",
        "size": 349067520
    },
    "CWEBTILE-3-2_NIRCam_2023-01-06": {
        "target_name": "CWEBTILE-3-2",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "COSMOS-Web: The JWST Cosmic Origins Survey",
        "parent_obsid": "107845240",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Field galaxies",
        "file_name": "jw01727-o044_t020_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-01-05 23:27:27.963",
        "end_time": "2023-01-05 23:53:03.325",
        "exposure_time": "0:17:10.728000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01727-o044_t020_nircam_clear-f277w_i2d.fits",
        "size": 349067520
    },
    "CWEBTILE-2-4_NIRCam_2023-01-06": {
        "target_name": "CWEBTILE-2-4",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "COSMOS-Web: The JWST Cosmic Origins Survey",
        "parent_obsid": "107881373",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Field galaxies",
        "file_name": "jw01727-o047_t035_nircam_clear-f444w_i2d.fits",
        "start_time": "2023-01-06 01:19:11.957",
        "end_time": "2023-01-06 01:44:47.382",
        "exposure_time": "0:17:10.728000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01727-o047_t035_nircam_clear-f444w_i2d.fits",
        "size": 349067520
    },
    "CWEBTILE-2-2_NIRCam_2023-01-06": {
        "target_name": "CWEBTILE-2-2",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "COSMOS-Web: The JWST Cosmic Origins Survey",
        "parent_obsid": "107907752",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Field galaxies",
        "file_name": "jw01727-o043_t019_nircam_clear-f277w_i2d.fits",
        "start_time": "2023-01-06 02:02:44.050",
        "end_time": "2023-01-06 02:28:19.476",
        "exposure_time": "0:17:10.728000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01727-o043_t019_nircam_clear-f277w_i2d.fits",
        "size": 349067520
    },
    "MACSJ0416.1-2403_NIRISS_2023-01-07": {
        "target_name": "MACSJ0416.1-2403",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F115W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "183624080",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o004_t002_niriss_clear-f115w_i2d.fits",
        "start_time": "2023-01-11 20:31:47.513",
        "end_time": "2023-01-12 00:42:55.092",
        "exposure_time": "0:22:54.308000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o004_t002_niriss_clear-f115w_i2d.fits",
        "size": 133983360
    },
    "MACS0647+7015_NIRCam_2023-01-08": {
        "target_name": "MACS0647+7015",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Physical Properties of the Triply-Lensed z = 11 Galaxy",
        "parent_obsid": "108153377",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01433-o020_t001_nircam_clear-f480m_i2d.fits",
        "start_time": "2023-01-08 17:46:11.999",
        "end_time": "2023-01-08 18:25:44.931",
        "exposure_time": "0:35:04.408000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01433-o020_t001_nircam_clear-f480m_i2d.fits",
        "size": 306630720
    },
    "ABELL370_NIRCam_2022-12-29": {
        "target_name": "ABELL370",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "107461408",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Abell clusters, Rich clusters",
        "file_name": "jw01208-o002_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-12-29 08:22:19.215",
        "end_time": "2022-12-29 10:27:56.459",
        "exposure_time": "1:46:39.116000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o002_t001_nircam_clear-f356w_i2d.fits",
        "size": 344039040
    },
    "MACSJ0416.1-2403_NIRCam_2022-12-29": {
        "target_name": "MACSJ0416.1-2403",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "JWST Medium-Deep Fields -- Windhorst IDS GTO Program",
        "parent_obsid": "182417942",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o004_t002_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-11 22:40:08.848",
        "end_time": "2023-01-12 00:43:15.892",
        "exposure_time": "1:46:39.116000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o004_t002_nircam_clear-f356w_i2d.fits",
        "size": 344041920
    },
    "ABELL370_NIRISS_2022-12-30": {
        "target_name": "ABELL370",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F115W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "107461690",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Abell clusters, Rich clusters",
        "file_name": "jw01208-o002_t001_niriss_clear-f115w_i2d.fits",
        "start_time": "2022-12-29 06:11:44.632",
        "end_time": "2022-12-29 10:27:32.331",
        "exposure_time": "0:22:54.308000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o002_t001_niriss_clear-f115w_i2d.fits",
        "size": 133983360
    },
    "ABELL370_NIRISS_2022-12-31": {
        "target_name": "ABELL370",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F115W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "107461690",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Abell clusters, Rich clusters",
        "file_name": "jw01208-o002_t001_niriss_clear-f115w_i2d.fits",
        "start_time": "2022-12-29 06:11:44.632",
        "end_time": "2022-12-29 10:27:32.331",
        "exposure_time": "0:22:54.308000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o002_t001_niriss_clear-f115w_i2d.fits",
        "size": 133983360
    },
    "GJ-436_NIRCam_2022-12-31": {
        "target_name": "GJ-436",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "WLP4",
        "obs_title": "Transit Spectroscopy of Mature Planets",
        "parent_obsid": "107465145",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Exoplanet Systems, M dwarfs, M stars",
        "file_name": "jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "start_time": "2022-12-31 22:16:05.494",
        "end_time": "2023-01-01 03:03:09.120",
        "exposure_time": "4:20:57.842000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw01185013001_04103_00001-seg007_nrca1_calints.fits",
        "size": 79280640
    },
    "PSF-STAR-SDSS-J0101+0007_NIRCam_2023-01-01": {
        "target_name": "PSF-STAR-SDSS-J0101+0007",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Unveiling Stellar Light from Host Galaxies of z~6 Quasars",
        "parent_obsid": "107477571",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "M stars",
        "file_name": "jw01813-o004_t010_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-01 15:50:08.218",
        "end_time": "2023-01-01 16:13:13.341",
        "exposure_time": "0:08:35.368000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01813-o004_t010_nircam_clear-f356w_i2d.fits",
        "size": 120628800
    },
    "NIRCAM-WFSS5_NIRCam_2022-12-24": {
        "target_name": "NIRCAM-WFSS5",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "The Cosmic Evolution Early Release Science (CEERS) Survey free",
        "parent_obsid": "107450366",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Emission line galaxies, High-redshift galaxies, Lyman-break galaxies, Primordial galaxies",
        "file_name": "jw01345-o012_t026_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-12-24 04:15:16.931",
        "end_time": "2022-12-24 19:19:42.661",
        "exposure_time": "0:31:08.196000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01345-o012_t026_nircam_clear-f356w_i2d.fits",
        "size": 419224320
    },
    "NIRCAM-WFSS7_NIRCam_2022-12-24": {
        "target_name": "NIRCAM-WFSS7",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "The Cosmic Evolution Early Release Science (CEERS) Survey free",
        "parent_obsid": "107450398",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Emission line galaxies, High-redshift galaxies, Lyman-break galaxies, Primordial galaxies",
        "file_name": "jw01345-o016_t027_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-12-24 19:37:38.509",
        "end_time": "2022-12-24 21:37:13.981",
        "exposure_time": "0:31:08.196000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01345-o016_t027_nircam_clear-f356w_i2d.fits",
        "size": 419526720
    },
    "J0911+0152_NIRCam_2022-12-25": {
        "target_name": "J0911+0152",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A Complete Census of Supermassive Black Holes and Host Galaxies at z=6",
        "parent_obsid": "107451816",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw01967-o016_t011_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-12-25 06:05:15.472",
        "end_time": "2022-12-25 07:22:12.379",
        "exposure_time": "0:51:32.192000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01967-o016_t011_nircam_clear-f356w_i2d.fits",
        "size": 132583680
    },
    "J0918+0139_NIRCam_2022-12-25": {
        "target_name": "J0918+0139",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A Complete Census of Supermassive Black Holes and Host Galaxies at z=6",
        "parent_obsid": "107451885",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw01967-o017_t007_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-12-25 07:49:00.298",
        "end_time": "2022-12-25 09:05:03.509",
        "exposure_time": "0:51:32.192000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01967-o017_t007_nircam_clear-f356w_i2d.fits",
        "size": 132583680
    },
    "NIRCAM-WFSS8_NIRCam_2022-12-20": {
        "target_name": "NIRCAM-WFSS8",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "The Cosmic Evolution Early Release Science (CEERS) Survey free",
        "parent_obsid": "106403459",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Emission line galaxies, High-redshift galaxies, Lyman-break galaxies, Primordial galaxies",
        "file_name": "jw01345-o015_t028_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-12-20 02:33:14.687",
        "end_time": "2022-12-20 04:04:08.497",
        "exposure_time": "0:31:08.196000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01345-o015_t028_nircam_clear-f356w_i2d.fits",
        "size": 419224320
    },
    "COS-2987030247_NIRCam_2022-12-21": {
        "target_name": "COS-2987030247",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "ALMA [OIII]88um Emitters. Signpost of Early Stellar Buildup and Reionization in the Universe",
        "parent_obsid": "106583743",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Lyman-alpha galaxies",
        "file_name": "jw01840-o012_t006_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-12-20 21:24:27.337",
        "end_time": "2022-12-20 21:38:03.315",
        "exposure_time": "0:09:18.312000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01840-o012_t006_nircam_clear-f444w_i2d.fits",
        "size": 132114240
    },
    "B14-65666_NIRCam_2022-12-21": {
        "target_name": "B14-65666",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "ALMA [OIII]88um Emitters. Signpost of Early Stellar Buildup and Reionization in the Universe",
        "parent_obsid": "106583721",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Lyman-alpha galaxies, Lyman-break galaxies",
        "file_name": "jw01840-o016_t010_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-12-20 23:15:29.761",
        "end_time": "2022-12-20 23:30:31.660",
        "exposure_time": "0:10:44.208000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01840-o016_t010_nircam_clear-f277w_i2d.fits",
        "size": 132114240
    },
    "NIRCAM-WFSS9_NIRCam_2022-12-21": {
        "target_name": "NIRCAM-WFSS9",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "The Cosmic Evolution Early Release Science (CEERS) Survey free",
        "parent_obsid": "106583833",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Emission line galaxies, High-redshift galaxies, Lyman-break galaxies, Primordial galaxies",
        "file_name": "jw01345-o013_t025_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-12-21 00:49:35.728",
        "end_time": "2022-12-21 02:23:47.170",
        "exposure_time": "0:31:08.196000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01345-o013_t025_nircam_clear-f356w_i2d.fits",
        "size": 419224320
    },
    "J1148+5251_NIRCam_2022-12-22": {
        "target_name": "J1148+5251",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Exploring the End of Cosmic Reionization",
        "parent_obsid": "106958581",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw01243-o007_t002_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-12-22 07:23:57.338",
        "end_time": "2022-12-22 07:52:47.518",
        "exposure_time": "0:17:32.204000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01243-o007_t002_nircam_clear-f356w_i2d.fits",
        "size": 398707200
    },
    "J1120+0641_NIRCam_2022-12-16": {
        "target_name": "J1120+0641",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Exploring the End of Cosmic Reionization",
        "parent_obsid": "109767044",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw01243-o009_t004_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-17 00:21:01.641",
        "end_time": "2023-01-17 00:51:19.053",
        "exposure_time": "0:17:32.204000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01243-o009_t004_nircam_clear-f356w_i2d.fits",
        "size": 398666880
    },
    "SGAS1226-PHOT_NIRCam_2022-12-16": {
        "target_name": "SGAS1226-PHOT",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "TEMPLATES: Targeting Extremely Magnified Panchromatic Lensed Arcs and Their Extended Star formation",
        "parent_obsid": "104983716",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies",
        "file_name": "jw01355-o003_t012_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-12-16 12:01:50.678",
        "end_time": "2022-12-16 12:09:53.918",
        "exposure_time": "0:04:49.893000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01355-o003_t012_nircam_clear-f277w_i2d.fits",
        "size": 331122240
    },
    "J0313-1806_NIRCam_2022-12-16": {
        "target_name": "J0313-1806",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F250M",
        "obs_title": "A Comprehensive JWST View of the Most Distant Quasars Deep Into the Epoch of Reionization",
        "parent_obsid": "104984306",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw01764-o009_t002_nircam_clear-f250m_i2d.fits",
        "start_time": "2022-12-16 17:48:29.130",
        "end_time": "2022-12-16 19:03:06.341",
        "exposure_time": "1:02:48.606000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01764-o009_t002_nircam_clear-f250m_i2d.fits",
        "size": 325298880
    },
    "J1120+0641_NIRCam_2022-12-17": {
        "target_name": "J1120+0641",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Exploring the End of Cosmic Reionization",
        "parent_obsid": "109767044",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw01243-o009_t004_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-17 00:21:01.641",
        "end_time": "2023-01-17 00:51:19.053",
        "exposure_time": "0:17:32.204000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01243-o009_t004_nircam_clear-f356w_i2d.fits",
        "size": 398666880
    },
    "ABELL370_NIRISS_2022-12-17": {
        "target_name": "ABELL370",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F115W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "107461690",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Abell clusters, Rich clusters",
        "file_name": "jw01208-o002_t001_niriss_clear-f115w_i2d.fits",
        "start_time": "2022-12-29 06:11:44.632",
        "end_time": "2022-12-29 10:27:32.331",
        "exposure_time": "0:22:54.308000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o002_t001_niriss_clear-f115w_i2d.fits",
        "size": 133983360
    },
    "J0148+0600_NIRCam_2022-12-13": {
        "target_name": "J0148+0600",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Exploring the End of Cosmic Reionization",
        "parent_obsid": "104173840",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Quasars",
        "file_name": "jw01243-o011_t006_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-12-13 15:39:35.636",
        "end_time": "2022-12-13 16:08:36.825",
        "exposure_time": "0:17:32.204000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01243-o011_t006_nircam_clear-f356w_i2d.fits",
        "size": 398223360
    },
    "J0921P0007_NIRCam_2022-11-29": {
        "target_name": "J0921P0007",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "102279352",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o013_t017_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-11-29 21:30:04.916",
        "end_time": "2022-11-29 22:00:08.773",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o013_t017_nircam_clear-f356w_i2d.fits",
        "size": 320477760
    },
    "J0923P0402_NIRCam_2022-11-30": {
        "target_name": "J0923P0402",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "102278928",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o014_t018_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-11-29 23:21:16.461",
        "end_time": "2022-11-29 23:51:20.319",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o014_t018_nircam_clear-f356w_i2d.fits",
        "size": 320474880
    },
    "NGC-253-IM-CENTER_NIRCam_2022-12-04": {
        "target_name": "NGC-253-IM-CENTER",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "Dissecting the Prototypical Starbursts NGC 253 and M 82 and Their Cool Galactic Winds",
        "parent_obsid": "198055454",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Disk galaxies, Starburst galaxies",
        "file_name": "jw01701-o057_t021_nircam_clear-f335m_i2d.fits",
        "start_time": "2023-12-04 16:19:46.356",
        "end_time": "2023-12-04 16:34:16.098",
        "exposure_time": "0:13:35.994000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01701-o057_t021_nircam_clear-f335m_i2d.fits",
        "size": 287104320
    },
    "NGC-1566_NIRCam_2022-11-22": {
        "target_name": "NGC-1566",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "101311995",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o025_t007_nircam_clear-f360m_i2d.fits",
        "start_time": "2022-11-22 13:28:51.029",
        "end_time": "2022-11-22 14:54:17.589",
        "exposure_time": "0:14:18.944000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o025_t007_nircam_clear-f360m_i2d.fits",
        "size": 249871680
    },
    "J1104P2134_NIRCam_2022-11-28": {
        "target_name": "J1104P2134",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "101812811",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o018_t022_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-11-28 02:46:07.382",
        "end_time": "2022-11-28 03:16:11.240",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o018_t022_nircam_clear-f356w_i2d.fits",
        "size": 320477760
    },
    "J0844-0132_NIRCam_2022-11-28": {
        "target_name": "J0844-0132",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A Complete Census of Supermassive Black Holes and Host Galaxies at z=6",
        "parent_obsid": "101813166",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw01967-o015_t009_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-11-28 08:09:58.343",
        "end_time": "2022-11-28 09:26:01.554",
        "exposure_time": "0:51:32.192000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01967-o015_t009_nircam_clear-f356w_i2d.fits",
        "size": 132583680
    },
    "ABELL2744-PREIMG-REPEAT1-19N47D_NIRCam_2022-11-15": {
        "target_name": "ABELL2744-PREIMG-REPEAT1-19N47D",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "UNCOVER: Ultra-deep NIRCam and NIRSpec Observations Before the Epoch of Reionization",
        "parent_obsid": "101094736",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Abell clusters",
        "file_name": "jw02561-o003_t006_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-11-15 12:13:08.203",
        "end_time": "2022-11-15 14:15:43.026",
        "exposure_time": "1:51:39.744000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02561-o003_t006_nircam_clear-f356w_i2d.fits",
        "size": 344041920
    },
    "NGC-253-IM-CENTER_NIRCam_2022-11-16": {
        "target_name": "NGC-253-IM-CENTER",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "Dissecting the Prototypical Starbursts NGC 253 and M 82 and Their Cool Galactic Winds",
        "parent_obsid": "198055454",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Disk galaxies, Starburst galaxies",
        "file_name": "jw01701-o057_t021_nircam_clear-f335m_i2d.fits",
        "start_time": "2023-12-04 16:19:46.356",
        "end_time": "2023-12-04 16:34:16.098",
        "exposure_time": "0:13:35.994000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01701-o057_t021_nircam_clear-f335m_i2d.fits",
        "size": 287104320
    },
    "JUPITER_NIRCam_2022-11-16": {
        "target_name": "JUPITER",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "CAR FGS-017 Straylight for Moving Targets (All SIs)",
        "parent_obsid": "87529766",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet",
        "file_name": "jw01022-o010_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-07-03 13:39:02.235",
        "end_time": "2022-07-03 13:42:37.034",
        "exposure_time": "0:03:13.262000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01022-o010_t001_nircam_clear-f356w_i2d.fits",
        "size": 286931520
    },
    "NGC-253_NIRCam_2022-11-16": {
        "target_name": "NGC-253",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "Securing the TRGB Distance Indicator: A Pre-Requisite for a JWST Measurement of H_0",
        "parent_obsid": "101124622",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Galaxy halos, Spiral galaxies",
        "file_name": "jw01638-o004_t004_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-11-16 13:50:02.128",
        "end_time": "2022-11-16 14:11:41.330",
        "exposure_time": "0:17:10.728000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01638-o004_t004_nircam_clear-f444w_i2d.fits",
        "size": 288714240
    },
    "BDF-3299_NIRCam_2022-11-17": {
        "target_name": "BDF-3299",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "ALMA [OIII]88um Emitters. Signpost of Early Stellar Buildup and Reionization in the Universe",
        "parent_obsid": "101246688",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Lyman-alpha galaxies",
        "file_name": "jw01840-o041_t008_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-11-17 18:13:26.404",
        "end_time": "2022-11-17 18:42:15.022",
        "exposure_time": "0:24:20.200000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01840-o041_t008_nircam_clear-f444w_i2d.fits",
        "size": 132114240
    },
    "WISE0855-0714_NIRCam_2022-11-19": {
        "target_name": "WISE0855-0714",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "The Physics of Brown Dwarfs - Part #3",
        "parent_obsid": "211290587",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw01230-o002_t001_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-11-19 08:18:05.982",
        "end_time": "2022-11-19 08:30:16.085",
        "exposure_time": "0:10:22.732000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01230-o002_t001_nircam_clear-f444w_i2d.fits",
        "size": 131440320
    },
    "WISE0855-0714_NIRISS_2022-11-19": {
        "target_name": "WISE0855-0714",
        "target_classification": "Star",
        "instrument_name": "NIRISS/image",
        "filters": "F480M",
        "obs_title": "The Physics of Brown Dwarfs - Part #3",
        "parent_obsid": "101247529",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw01230-o004_t001_niriss_clearp-f480m_i2d.fits",
        "start_time": "2022-11-19 08:49:28.100",
        "end_time": "2022-11-19 09:46:47.376",
        "exposure_time": "0:36:30.301000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01230-o004_t001_niriss_clearp-f480m_i2d.fits",
        "size": 118128960
    },
    "WISE-0647-62_NIRCam_2022-11-11": {
        "target_name": "WISE-0647-62",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "100857027",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw02473-o058_t007_nircam_clear-f480m_i2d.fits",
        "start_time": "2022-11-11 16:06:06.538",
        "end_time": "2022-11-11 17:10:53.277",
        "exposure_time": "0:58:09.450000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o058_t007_nircam_clear-f480m_i2d.fits",
        "size": 120585600
    },
    "WISE-0713-29_NIRCam_2022-11-12": {
        "target_name": "WISE-0713-29",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "100857019",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw02473-o059_t008_nircam_clear-f480m_i2d.fits",
        "start_time": "2022-11-12 08:23:35.784",
        "end_time": "2022-11-12 09:01:31.973",
        "exposure_time": "0:31:18.935000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o059_t008_nircam_clear-f480m_i2d.fits",
        "size": 120585600
    },
    "WISE-0825+28_NIRCam_2022-11-12": {
        "target_name": "WISE-0825+28",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "100857023",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw02473-o061_t010_nircam_clear-f480m_i2d.fits",
        "start_time": "2022-11-12 09:47:18.780",
        "end_time": "2022-11-12 10:34:11.840",
        "exposure_time": "0:40:15.775000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o061_t010_nircam_clear-f480m_i2d.fits",
        "size": 120585600
    },
    "WISE-0830+28_NIRCam_2022-11-12": {
        "target_name": "WISE-0830+28",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "100857308",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Brown dwarfs, Y dwarfs",
        "file_name": "jw02473-o062_t054_nircam_clear-f480m_i2d.fits",
        "start_time": "2022-11-12 21:46:00.107",
        "end_time": "2022-11-12 23:53:46.129",
        "exposure_time": "2:00:47.320000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o062_t054_nircam_clear-f480m_i2d.fits",
        "size": 120585600
    },
    "J0910M0414_NIRCam_2022-11-13": {
        "target_name": "J0910M0414",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "100887296",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o012_t016_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-11-13 21:47:29.389",
        "end_time": "2022-11-13 22:17:33.246",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o012_t016_nircam_clear-f356w_i2d.fits",
        "size": 334146240
    },
    "BDF-3299_NIRCam_2022-11-03": {
        "target_name": "BDF-3299",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "ALMA [OIII]88um Emitters. Signpost of Early Stellar Buildup and Reionization in the Universe",
        "parent_obsid": "101246688",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Lyman-alpha galaxies",
        "file_name": "jw01840-o041_t008_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-11-17 18:13:26.404",
        "end_time": "2022-11-17 18:42:15.022",
        "exposure_time": "0:24:20.200000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01840-o041_t008_nircam_clear-f444w_i2d.fits",
        "size": 132114240
    },
    "J2236+0032_NIRCam_2022-11-05": {
        "target_name": "J2236+0032",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A Complete Census of Supermassive Black Holes and Host Galaxies at z=6",
        "parent_obsid": "100098541",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw01967-o023_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-11-05 06:33:13.818",
        "end_time": "2022-11-05 07:49:17.028",
        "exposure_time": "0:51:32.192000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01967-o023_t001_nircam_clear-f356w_i2d.fits",
        "size": 132583680
    },
    "I-ZW-18-NIRCAM_NIRCam_2022-10-29": {
        "target_name": "I-ZW-18-NIRCAM",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "I Zw 18: Dust Life Cycle at Very Low Metallicity",
        "parent_obsid": "99412628",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf irregular galaxies",
        "file_name": "jw01233-o002_t002_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-10-29 17:26:17.406",
        "end_time": "2022-10-29 17:51:42.004",
        "exposure_time": "0:20:45.464000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01233-o002_t002_nircam_clear-f356w_i2d.fits",
        "size": 291191040
    },
    "G191-B2B_NIRCam_2022-10-30": {
        "target_name": "G191-B2B",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (White Dwarfs)",
        "parent_obsid": "94965180",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "White dwarfs",
        "file_name": "jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-09-24 15:33:09.036",
        "end_time": "2022-09-24 15:36:43.921",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "size": 287524800
    },
    "J0525M2406_NIRCam_2022-10-12": {
        "target_name": "J0525M2406",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "97278954",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o009_t013_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-10-12 22:28:32.054",
        "end_time": "2022-10-12 22:58:35.976",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o009_t013_nircam_clear-f356w_i2d.fits",
        "size": 320477760
    },
    "HE-0435-1223_NIRCam_2022-10-12": {
        "target_name": "HE-0435-1223",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "IFU spectroscopy of the host galaxies of strongly lensed quasars",
        "parent_obsid": "97278410",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Elliptical galaxies, Quasars",
        "file_name": "jw01198-o007_t005_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-10-12 04:24:41.508",
        "end_time": "2022-10-12 05:00:07.443",
        "exposure_time": "0:30:03.776000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01198-o007_t005_nircam_clear-f356w_i2d.fits",
        "size": 344010240
    },
    "MACSJ0417.5-1154_NIRISS_2022-10-12": {
        "target_name": "MACSJ0417.5-1154",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRISS/image",
        "filters": "CLEAR;F115W",
        "obs_title": "CANUCS: The CAnadian NIRISS Unbiased Cluster Survey",
        "parent_obsid": "97285071",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o006_t003_niriss_clear-f115w_i2d.fits",
        "start_time": "2022-10-13 14:00:16.394",
        "end_time": "2022-10-13 18:11:54.564",
        "exposure_time": "0:22:54.308000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o006_t003_niriss_clear-f115w_i2d.fits",
        "size": 134104320
    },
    "HD-38087_NIRCam_2022-10-12": {
        "target_name": "HD-38087",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "WLP4",
        "obs_title": "Completing the puzzle of interstellar dust grain compositions: combining extinction, depletion and molecular hydrogen measurements",
        "parent_obsid": "97278521",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B giants, Multiple stars",
        "file_name": "jw02459003001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2022-10-12 17:02:17.116",
        "end_time": "2022-10-12 17:02:49.437",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02459003001_03103_00001-seg001_nrca3_calints.fits",
        "size": 52528320
    },
    "RXJ2129_NIRCam_2022-10-06": {
        "target_name": "RXJ2129",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Imaging and Spectroscopy of Three Highly Magnified Images of a Supernova at z=1.5",
        "parent_obsid": "97242354",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw02767-o002_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-10-06 10:18:17.568",
        "end_time": "2022-10-06 11:50:05.577",
        "exposure_time": "1:23:01.864000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02767-o002_t001_nircam_clear-f356w_i2d.fits",
        "size": 132128640
    },
    "MACSJ0416.1-2403_NIRCam_2022-10-07": {
        "target_name": "MACSJ0416.1-2403",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "JWST Medium-Deep Fields -- Windhorst IDS GTO Program",
        "parent_obsid": "182417942",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01208-o004_t002_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-01-11 22:40:08.848",
        "end_time": "2023-01-12 00:43:15.892",
        "exposure_time": "1:46:39.116000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01208-o004_t002_nircam_clear-f356w_i2d.fits",
        "size": 344041920
    },
    "PLUTO_NIRCam_2022-10-08": {
        "target_name": "PLUTO",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F090W",
        "obs_title": "Plutos climate system with JWST",
        "parent_obsid": "97240969",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf-Planet",
        "file_name": "jw01658-o009_t001_nircam_clear-f090w-sub320_i2d.fits",
        "start_time": "2022-10-05 22:03:33.206",
        "end_time": "2022-10-05 22:09:31.409",
        "exposure_time": "0:02:51.046000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01658-o009_t001_nircam_clear-f090w-sub320_i2d.fits",
        "size": 31530240
    },
    "PLUTO_NIRCam_2022-10-10": {
        "target_name": "PLUTO",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F090W",
        "obs_title": "Plutos climate system with JWST",
        "parent_obsid": "97240969",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf-Planet",
        "file_name": "jw01658-o009_t001_nircam_clear-f090w-sub320_i2d.fits",
        "start_time": "2022-10-05 22:03:33.206",
        "end_time": "2022-10-05 22:09:31.409",
        "exposure_time": "0:02:51.046000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01658-o009_t001_nircam_clear-f090w-sub320_i2d.fits",
        "size": 31530240
    },
    "PLEIADES1_NIRCam_2022-09-28": {
        "target_name": "PLEIADES1",
        "target_classification": "ISM",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "The turbulent magnetized interstellar medium: looking for ambipolar diffusion in the Pleiades",
        "parent_obsid": "95382717",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Cold neutral medium, Diffuse interstellar clouds, Interstellar magnetic fields, Reflection nebulae",
        "file_name": "jw02143-o001_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-09-28 05:18:43.974",
        "end_time": "2022-09-28 06:33:10.531",
        "exposure_time": "1:06:34.080000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02143-o001_t001_nircam_clear-f277w_i2d.fits",
        "size": 360357120
    },
    "NGC-2403_NIRCam_2022-10-03": {
        "target_name": "NGC-2403",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Securing the TRGB Distance Indicator: A Pre-Requisite for a JWST Measurement of H_0",
        "parent_obsid": "96498801",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Galaxy halos, Spiral galaxies",
        "file_name": "jw01638-o001_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-10-03 02:29:57.508",
        "end_time": "2022-10-03 02:51:47.462",
        "exposure_time": "0:17:10.728000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01638-o001_t001_nircam_clear-f356w_i2d.fits",
        "size": 288714240
    },
    "GD71_NIRCam_2022-09-22": {
        "target_name": "GD71",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F150W2;F164N",
        "obs_title": "Absolute Flux Calibration (White Dwarfs)",
        "parent_obsid": "94086002",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "White dwarfs",
        "file_name": "jw01537-o014_t002_nircam_f150w2-f164n-sub160_i2d.fits",
        "start_time": "2022-09-21 21:17:35.952",
        "end_time": "2022-09-22 02:11:47.321",
        "exposure_time": "0:18:12.272000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01537-o014_t002_nircam_f150w2-f164n-sub160_i2d.fits",
        "size": 16018560
    },
    "WISE-0336-01_NIRCam_2022-09-22": {
        "target_name": "WISE-0336-01",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "94145983",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Brown dwarfs, Y dwarfs",
        "file_name": "jw02473-o053_t053_nircam_clear-f480m_i2d.fits",
        "start_time": "2022-09-22 12:49:44.269",
        "end_time": "2022-09-22 13:36:37.328",
        "exposure_time": "0:40:15.775000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o053_t053_nircam_clear-f480m_i2d.fits",
        "size": 120585600
    },
    "J2002M3013_NIRCam_2022-09-22": {
        "target_name": "J2002M3013",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "94161943",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o022_t026_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-22 20:53:22.003",
        "end_time": "2022-09-22 21:23:15.109",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o022_t026_nircam_clear-f356w_i2d.fits",
        "size": 320477760
    },
    "J2102M1458_NIRCam_2022-09-23": {
        "target_name": "J2102M1458",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "95066826",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o023_t027_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-23 05:07:41.046",
        "end_time": "2022-09-23 05:37:55.656",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o023_t027_nircam_clear-f356w_i2d.fits",
        "size": 320477760
    },
    "LDN-694_NIRCam_2022-09-23": {
        "target_name": "LDN-694",
        "target_classification": "ISM",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Extinction Mapping of Pre-stellar Cores",
        "parent_obsid": "93702204",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dark interstellar clouds, Dense interstellar clouds",
        "file_name": "jw01187-o025_t002_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-20 08:15:06.141",
        "end_time": "2022-09-20 09:44:29.327",
        "exposure_time": "0:05:43.576000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01187-o025_t002_nircam_clear-f356w_i2d.fits",
        "size": 429223680
    },
    "IIZW96_NIRCam_2022-09-23": {
        "target_name": "IIZW96",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A JWST Study of the Starburst-AGN Connection in Merging LIRGs",
        "parent_obsid": "94401396",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Infrared galaxies, Interacting galaxies",
        "file_name": "jw01328-o037_t029_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-23 09:03:40.327",
        "end_time": "2022-09-23 09:24:47.291",
        "exposure_time": "0:09:39.789000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01328-o037_t029_nircam_clear-f356w_i2d.fits",
        "size": 150076800
    },
    "MACS0647+7015_NIRCam_2022-09-23": {
        "target_name": "MACS0647+7015",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Physical Properties of the Triply-Lensed z = 11 Galaxy",
        "parent_obsid": "108153377",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01433-o020_t001_nircam_clear-f480m_i2d.fits",
        "start_time": "2023-01-08 17:46:11.999",
        "end_time": "2023-01-08 18:25:44.931",
        "exposure_time": "0:35:04.408000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01433-o020_t001_nircam_clear-f480m_i2d.fits",
        "size": 306630720
    },
    "WISE-0359-54_NIRCam_2022-09-23": {
        "target_name": "WISE-0359-54",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "94441047",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw02473-o055_t004_nircam_clear-f480m_i2d.fits",
        "start_time": "2022-09-23 17:03:36.158",
        "end_time": "2022-09-23 18:39:41.786",
        "exposure_time": "1:29:28.385000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o055_t004_nircam_clear-f480m_i2d.fits",
        "size": 120585600
    },
    "RXC-J2248-ID3_NIRCam_2022-09-24": {
        "target_name": "RXC-J2248-ID3",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F300M",
        "obs_title": "ALMA [OIII]88um Emitters. Signpost of Early Stellar Buildup and Reionization in the Universe",
        "parent_obsid": "94634985",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Lyman-alpha galaxies",
        "file_name": "jw01840-o010_t003_nircam_clear-f300m_i2d.fits",
        "start_time": "2022-09-24 19:41:47.311",
        "end_time": "2022-09-24 20:03:26.449",
        "exposure_time": "0:17:10.728000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01840-o010_t003_nircam_clear-f300m_i2d.fits",
        "size": 132114240
    },
    "G191-B2B_NIRCam_2022-09-24": {
        "target_name": "G191-B2B",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (White Dwarfs)",
        "parent_obsid": "94965180",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "White dwarfs",
        "file_name": "jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-09-24 15:33:09.036",
        "end_time": "2022-09-24 15:36:43.921",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "size": 287524800
    },
    "WISE-0410+15_NIRCam_2022-09-24": {
        "target_name": "WISE-0410+15",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "94546968",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw02473-o056_t005_nircam_clear-f480m_i2d.fits",
        "start_time": "2022-09-23 23:28:06.784",
        "end_time": "2022-09-23 23:59:47.208",
        "exposure_time": "0:26:50.515000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o056_t005_nircam_clear-f480m_i2d.fits",
        "size": 120585600
    },
    "WISE-0304-27_NIRCam_2022-09-24": {
        "target_name": "WISE-0304-27",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F480M",
        "obs_title": "Multiplicity Survey of 20 Y Dwarfs with NIRCam Kernel Phase Interferometry",
        "parent_obsid": "94546963",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Y dwarfs",
        "file_name": "jw02473-o052_t052_nircam_clear-f480m_i2d.fits",
        "start_time": "2022-09-23 21:10:00.143",
        "end_time": "2022-09-23 22:46:05.772",
        "exposure_time": "1:29:28.385000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02473-o052_t052_nircam_clear-f480m_i2d.fits",
        "size": 120585600
    },
    "LQAC-308-047-001_NIRCam_2022-09-19": {
        "target_name": "LQAC-308-047-001",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "IFU spectroscopy of the host galaxies of strongly lensed quasars",
        "parent_obsid": "93480302",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Elliptical galaxies, Quasars",
        "file_name": "jw01198-o004_t004_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-19 05:11:59.810",
        "end_time": "2022-09-19 05:46:53.553",
        "exposure_time": "0:30:03.776000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01198-o004_t004_nircam_clear-f356w_i2d.fits",
        "size": 344013120
    },
    "TYC-7380-1046-1_NIRCam_2022-09-19": {
        "target_name": "TYC-7380-1046-1",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "93519269",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183020001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2022-09-19 13:46:30.198",
        "end_time": "2022-09-19 13:46:46.421",
        "exposure_time": "0:00:13.519000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183020001_03103_00001-seg001_nrca3_calints.fits",
        "size": 26323200
    },
    "GALCEN_NIRCam_2022-09-19": {
        "target_name": "GALCEN",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F322W2;F323N",
        "obs_title": "The Galactic Center",
        "parent_obsid": "93557305",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Stellar associations, Young star clusters",
        "file_name": "jw01939-o001_t001_nircam_f322w2-f323n_i2d.fits",
        "start_time": "2022-09-19 14:20:28.834",
        "end_time": "2022-09-19 15:21:30.127",
        "exposure_time": "0:42:56.820000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01939-o001_t001_nircam_f322w2-f323n_i2d.fits",
        "size": 307221120
    },
    "SNAKE-FIELD-2_NIRCam_2022-09-20": {
        "target_name": "SNAKE-FIELD-2",
        "target_classification": "ISM",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "NIRCam GTO - IRDC Mapping",
        "parent_obsid": "93702295",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dark interstellar clouds, Dense interstellar clouds, Dust nebulae, Protostars",
        "file_name": "jw01182-o002_t004_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-20 01:03:33.988",
        "end_time": "2022-09-20 02:25:53.000",
        "exposure_time": "1:08:42.924000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01182-o002_t004_nircam_clear-f356w_i2d.fits",
        "size": 360460800
    },
    "TYC-6272-339-1_NIRCam_2022-09-20": {
        "target_name": "TYC-6272-339-1",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "93695932",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183024001_03103_00001-seg001_nrca1_calints.fits",
        "start_time": "2022-09-20 04:38:54.112",
        "end_time": "2022-09-20 04:39:26.433",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183024001_03103_00001-seg001_nrca1_calints.fits",
        "size": 52528320
    },
    "LS-4992_NIRCam_2022-09-20": {
        "target_name": "LS-4992",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "93695943",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183027001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2022-09-20 05:35:27.004",
        "end_time": "2022-09-20 05:35:59.325",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183027001_03103_00001-seg001_nrca3_calints.fits",
        "size": 52528320
    },
    "LDN-694_NIRCam_2022-09-20": {
        "target_name": "LDN-694",
        "target_classification": "ISM",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Extinction Mapping of Pre-stellar Cores",
        "parent_obsid": "93702204",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dark interstellar clouds, Dense interstellar clouds",
        "file_name": "jw01187-o025_t002_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-20 08:15:06.141",
        "end_time": "2022-09-20 09:44:29.327",
        "exposure_time": "0:05:43.576000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01187-o025_t002_nircam_clear-f356w_i2d.fits",
        "size": 429223680
    },
    "CL-WESTERLUND-CF_NIRCam_2022-09-14": {
        "target_name": "CL-WESTERLUND-CF",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Testing protoplanetary disk evolution and brown dwarf formation in starburst: NIRCAM and MIRI observations of the young cluster Westerlund 1",
        "parent_obsid": "93017111",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "O clusters, Open star clusters, Young star clusters",
        "file_name": "jw01905-o003_t002_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-09-14 01:57:06.076",
        "end_time": "2022-09-14 02:02:28.176",
        "exposure_time": "0:02:08.841000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01905-o003_t002_nircam_clear-f277w_i2d.fits",
        "size": 335134080
    },
    "LDN-694_NIRCam_2022-09-14": {
        "target_name": "LDN-694",
        "target_classification": "ISM",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Extinction Mapping of Pre-stellar Cores",
        "parent_obsid": "93702204",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dark interstellar clouds, Dense interstellar clouds",
        "file_name": "jw01187-o025_t002_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-20 08:15:06.141",
        "end_time": "2022-09-20 09:44:29.327",
        "exposure_time": "0:05:43.576000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01187-o025_t002_nircam_clear-f356w_i2d.fits",
        "size": 429223680
    },
    "VI-CYG-1_NIRCam_2022-09-14": {
        "target_name": "VI-CYG-1",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "92934488",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "O stars",
        "file_name": "jw02183003001_03103_00001-seg001_nrca1_calints.fits",
        "start_time": "2022-09-14 13:30:48.679",
        "end_time": "2022-09-14 13:31:21.000",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183003001_03103_00001-seg001_nrca1_calints.fits",
        "size": 52528320
    },
    "2MASS-J20452110+4223513_NIRCam_2022-09-14": {
        "target_name": "2MASS-J20452110+4223513",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "92934495",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183014001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2022-09-14 14:03:28.037",
        "end_time": "2022-09-14 14:04:00.358",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183014001_03103_00001-seg001_nrca3_calints.fits",
        "size": 52528320
    },
    "LDN-694_NIRCam_2022-09-16": {
        "target_name": "LDN-694",
        "target_classification": "ISM",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Extinction Mapping of Pre-stellar Cores",
        "parent_obsid": "93702204",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dark interstellar clouds, Dense interstellar clouds",
        "file_name": "jw01187-o025_t002_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-20 08:15:06.141",
        "end_time": "2022-09-20 09:44:29.327",
        "exposure_time": "0:05:43.576000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01187-o025_t002_nircam_clear-f356w_i2d.fits",
        "size": 429223680
    },
    "CPR2002-A38_NIRCam_2022-09-16": {
        "target_name": "CPR2002-A38",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "93071248",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "O stars",
        "file_name": "jw02183005001_03103_00001-seg001_nrca1_calints.fits",
        "start_time": "2022-09-16 18:58:34.575",
        "end_time": "2022-09-16 18:59:06.896",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183005001_03103_00001-seg001_nrca1_calints.fits",
        "size": 52528320
    },
    "ALS-15181_NIRCam_2022-09-16": {
        "target_name": "ALS-15181",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "93071282",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183008001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2022-09-16 19:43:39.084",
        "end_time": "2022-09-16 19:44:11.405",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183008001_03103_00001-seg001_nrca3_calints.fits",
        "size": 52528320
    },
    "GSC-03157-00327_NIRCam_2022-09-16": {
        "target_name": "GSC-03157-00327",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "93071319",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "O stars",
        "file_name": "jw02183011001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2022-09-16 20:21:28.010",
        "end_time": "2022-09-16 20:22:00.331",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183011001_03103_00001-seg001_nrca3_calints.fits",
        "size": 52528320
    },
    "2MASS-J20452110+4223513_NIRCam_2022-09-16": {
        "target_name": "2MASS-J20452110+4223513",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F212N;WLP8",
        "obs_title": "Illuminating the dust properties in the diffuse ISM with JWST",
        "parent_obsid": "92934495",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "B stars",
        "file_name": "jw02183014001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2022-09-14 14:03:28.037",
        "end_time": "2022-09-14 14:04:00.358",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02183014001_03103_00001-seg001_nrca3_calints.fits",
        "size": 52528320
    },
    "J1743045_NIRCam_2022-09-16": {
        "target_name": "J1743045",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (A Dwarfs)",
        "parent_obsid": "89383459",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "A dwarfs",
        "file_name": "jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-08-22 10:09:42.583",
        "end_time": "2022-08-22 10:13:17.404",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "size": 287968320
    },
    "J1743045_NIRCam_2022-09-17": {
        "target_name": "J1743045",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (A Dwarfs)",
        "parent_obsid": "89383459",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "A dwarfs",
        "file_name": "jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-08-22 10:09:42.583",
        "end_time": "2022-08-22 10:13:17.404",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "size": 287968320
    },
    "G191-B2B_NIRCam_2022-09-13": {
        "target_name": "G191-B2B",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (White Dwarfs)",
        "parent_obsid": "94965180",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "White dwarfs",
        "file_name": "jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-09-24 15:33:09.036",
        "end_time": "2022-09-24 15:36:43.921",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "size": 287524800
    },
    "HD-216898_NIRCam_2022-09-07": {
        "target_name": "HD-216898",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "WLP4",
        "obs_title": "Completing the puzzle of interstellar dust grain compositions: combining extinction, depletion and molecular hydrogen measurements",
        "parent_obsid": "91478722",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "O stars",
        "file_name": "jw02459017001_03103_00001-seg001_nrca1_calints.fits",
        "start_time": "2022-09-08 00:47:59.048",
        "end_time": "2022-09-08 00:48:15.271",
        "exposure_time": "0:00:13.519000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02459017001_03103_00001-seg001_nrca1_calints.fits",
        "size": 26323200
    },
    "LDN-1527_NIRCam_2022-09-08": {
        "target_name": "LDN-1527",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "JWST Cycle 1 Outreach Campaign",
        "parent_obsid": "91482977",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Protostars",
        "file_name": "jw02739-o005_t004_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-09-08 03:06:58.032",
        "end_time": "2022-09-08 03:41:08.829",
        "exposure_time": "0:24:20.200000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02739-o005_t004_nircam_clear-f444w_i2d.fits",
        "size": 144100800
    },
    "2010DF1_NIRCam_2022-09-08": {
        "target_name": "2010DF1",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F322W2;F323N",
        "obs_title": "Moving Target Tracking Test",
        "parent_obsid": "91491858",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Asteroid",
        "file_name": "jw02744-o002_t002_nircam_f322w2-f323n_i2d.fits",
        "start_time": "2022-09-08 06:51:24.378",
        "end_time": "2022-09-08 06:56:46.481",
        "exposure_time": "0:03:34.735000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02744-o002_t002_nircam_f322w2-f323n_i2d.fits",
        "size": 120384000
    },
    "G191-B2B_NIRCam_2022-09-08": {
        "target_name": "G191-B2B",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (White Dwarfs)",
        "parent_obsid": "94965180",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "White dwarfs",
        "file_name": "jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-09-24 15:33:09.036",
        "end_time": "2022-09-24 15:36:43.921",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "size": 287524800
    },
    "238P-READ_NIRCam_2022-09-08": {
        "target_name": "238P-READ",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Spectral mapping of a comets inner coma",
        "parent_obsid": "91570912",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Comet",
        "file_name": "jw01252-o005_t003_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-09-08 14:45:04.038",
        "end_time": "2022-09-08 15:14:24.948",
        "exposure_time": "0:16:59.995000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01252-o005_t003_nircam_clear-f277w_i2d.fits",
        "size": 344845440
    },
    "G191-B2B_NIRCam_2022-09-09": {
        "target_name": "G191-B2B",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (White Dwarfs)",
        "parent_obsid": "94965180",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "White dwarfs",
        "file_name": "jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-09-24 15:33:09.036",
        "end_time": "2022-09-24 15:36:43.921",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "size": 287524800
    },
    "2010DF1_NIRCam_2022-09-09": {
        "target_name": "2010DF1",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F322W2;F323N",
        "obs_title": "Moving Target Tracking Test",
        "parent_obsid": "91491858",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Asteroid",
        "file_name": "jw02744-o002_t002_nircam_f322w2-f323n_i2d.fits",
        "start_time": "2022-09-08 06:51:24.378",
        "end_time": "2022-09-08 06:56:46.481",
        "exposure_time": "0:03:34.735000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02744-o002_t002_nircam_f322w2-f323n_i2d.fits",
        "size": 120384000
    },
    "J0235-0532_NIRCam_2022-09-09": {
        "target_name": "J0235-0532",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "ALMA [OIII]88um Emitters. Signpost of Early Stellar Buildup and Reionization in the Universe",
        "parent_obsid": "91940657",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Lyman-alpha galaxies",
        "file_name": "jw01840-o004_t002_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-09-09 22:19:35.108",
        "end_time": "2022-09-09 22:29:47.130",
        "exposure_time": "0:05:43.576000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01840-o004_t002_nircam_clear-f444w_i2d.fits",
        "size": 132114240
    },
    "J1743045_NIRCam_2022-09-10": {
        "target_name": "J1743045",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (A Dwarfs)",
        "parent_obsid": "89383459",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "A dwarfs",
        "file_name": "jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-08-22 10:09:42.583",
        "end_time": "2022-08-22 10:13:17.404",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "size": 287968320
    },
    "G191-B2B_NIRCam_2022-09-10": {
        "target_name": "G191-B2B",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (White Dwarfs)",
        "parent_obsid": "94965180",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "White dwarfs",
        "file_name": "jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-09-24 15:33:09.036",
        "end_time": "2022-09-24 15:36:43.921",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "size": 287524800
    },
    "G191-B2B_NIRCam_2022-09-11": {
        "target_name": "G191-B2B",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (White Dwarfs)",
        "parent_obsid": "94965180",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "White dwarfs",
        "file_name": "jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-09-24 15:33:09.036",
        "end_time": "2022-09-24 15:36:43.921",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01537-o036_t001_nircam_clear-f277w_i2d.fits",
        "size": 287524800
    },
    "ORIBAR-IMAGING-NIRCAM_NIRCam_2022-09-11": {
        "target_name": "ORIBAR-IMAGING-NIRCAM",
        "target_classification": "ISM",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Radiative Feedback from Massive Stars as Traced by Multiband Imaging and Spectroscopic Mosaics",
        "parent_obsid": "92317691",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dense interstellar clouds, Dust nebulae, Molecular gas, Nebulae, Photodissociation regions",
        "file_name": "jw01288-o001_t011_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-09-10 19:18:25.100",
        "end_time": "2022-09-10 19:30:13.808",
        "exposure_time": "0:02:51.788000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01288-o001_t011_nircam_clear-f277w_i2d.fits",
        "size": 360423360
    },
    "J0430M1445_NIRCam_2022-09-12": {
        "target_name": "J0430M1445",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "92557206",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o008_t012_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-11 14:56:04.564",
        "end_time": "2022-09-11 15:25:57.670",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o008_t012_nircam_clear-f356w_i2d.fits",
        "size": 320477760
    },
    "P330E_NIRCam_2022-08-29": {
        "target_name": "P330E",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F405N",
        "obs_title": "Absolute Flux Calibration (G Dwarfs)",
        "parent_obsid": "87623322",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "start_time": "2022-07-02 17:53:27.661",
        "end_time": "2022-07-02 17:56:08.688",
        "exposure_time": "0:01:04.420000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "size": 120752640
    },
    "P330E_NIRCam_2022-08-30": {
        "target_name": "P330E",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F405N",
        "obs_title": "Absolute Flux Calibration (G Dwarfs)",
        "parent_obsid": "87623322",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "start_time": "2022-07-02 17:53:27.661",
        "end_time": "2022-07-02 17:56:08.688",
        "exposure_time": "0:01:04.420000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "size": 120752640
    },
    "HD-14434_NIRCam_2022-09-01": {
        "target_name": "HD-14434",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "WLP4",
        "obs_title": "Completing the puzzle of interstellar dust grain compositions: combining extinction, depletion and molecular hydrogen measurements",
        "parent_obsid": "90797073",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Emission line stars, O stars",
        "file_name": "jw02459001001_03103_00001-seg001_nrca3_calints.fits",
        "start_time": "2022-09-01 15:36:05.072",
        "end_time": "2022-09-01 15:36:37.393",
        "exposure_time": "0:00:26.934000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw02459001001_03103_00001-seg001_nrca3_calints.fits",
        "size": 52528320
    },
    "SN-1987A_NIRCam_2022-09-01": {
        "target_name": "SN-1987A",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "SN 1987A: The Formation and Evolution of Dust in a Supernova Explosion",
        "parent_obsid": "87630463",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Supernovae",
        "file_name": "jw01232-o001_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-07-16 13:41:40.829",
        "end_time": "2022-07-16 14:03:30.716",
        "exposure_time": "0:14:18.940000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01232-o001_t001_nircam_clear-f277w_i2d.fits",
        "size": 126167040
    },
    "J1743045_NIRCam_2022-09-03": {
        "target_name": "J1743045",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (A Dwarfs)",
        "parent_obsid": "89383459",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "A dwarfs",
        "file_name": "jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-08-22 10:09:42.583",
        "end_time": "2022-08-22 10:13:17.404",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "size": 287968320
    },
    "NGC-6822-NIRCAM_NIRCam_2022-09-05": {
        "target_name": "NGC-6822-NIRCAM",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "NGC 6822: Dust Life Cycle Study of a Nearby Low Metallicity Galaxy",
        "parent_obsid": "90946541",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Irregular galaxies",
        "file_name": "jw01234-o006_t003_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-05 00:09:37.742",
        "end_time": "2022-09-05 00:59:22.919",
        "exposure_time": "0:30:03.780000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01234-o006_t003_nircam_clear-f356w_i2d.fits",
        "size": 349920000
    },
    "NGC-6822-NIRCAM-TILE-1_NIRCam_2022-09-05": {
        "target_name": "NGC-6822-NIRCAM-TILE-1",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "NGC 6822: Dust Life Cycle Study of a Nearby Low Metallicity Galaxy",
        "parent_obsid": "90976720",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Irregular galaxies",
        "file_name": "jw01234-o010_t006_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-09-05 02:10:40.647",
        "end_time": "2022-09-05 03:00:47.072",
        "exposure_time": "0:30:03.780000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01234-o010_t006_nircam_clear-f356w_i2d.fits",
        "size": 353934720
    },
    "J1743045_NIRCam_2022-08-24": {
        "target_name": "J1743045",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (A Dwarfs)",
        "parent_obsid": "89383459",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "A dwarfs",
        "file_name": "jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-08-22 10:09:42.583",
        "end_time": "2022-08-22 10:13:17.404",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "size": 287968320
    },
    "SNAKE-FIELD-1_NIRCam_2022-08-27": {
        "target_name": "SNAKE-FIELD-1",
        "target_classification": "ISM",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "NIRCam GTO - IRDC Mapping",
        "parent_obsid": "90798335",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Cold neutral medium, Dark interstellar clouds, Dust nebulae, Interstellar clouds, Protostars",
        "file_name": "jw01182-o001_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-08-27 02:44:08.627",
        "end_time": "2022-08-27 04:06:59.768",
        "exposure_time": "1:08:42.924000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01182-o001_t001_nircam_clear-f356w_i2d.fits",
        "size": 360463680
    },
    "SCULPTOR-F0_NIRCam_2022-08-15": {
        "target_name": "SCULPTOR-F0",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Internal Dynamics of Milky Way Dwarf Spheroidal Galaxies",
        "parent_obsid": "89363273",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf spheroidal galaxies",
        "file_name": "jw01304-o004_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-08-15 11:53:29.465",
        "end_time": "2022-08-15 12:08:20.628",
        "exposure_time": "0:10:01.260000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01304-o004_t004_nircam_clear-f277w_i2d.fits",
        "size": 288711360
    },
    "SCULPTOR-F1_NIRCam_2022-08-15": {
        "target_name": "SCULPTOR-F1",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Internal Dynamics of Milky Way Dwarf Spheroidal Galaxies",
        "parent_obsid": "89326735",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf spheroidal galaxies",
        "file_name": "jw01304-o005_t005_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-08-15 12:31:18.454",
        "end_time": "2022-08-15 12:46:09.618",
        "exposure_time": "0:10:01.260000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01304-o005_t005_nircam_clear-f277w_i2d.fits",
        "size": 288650880
    },
    "SXDF-NB1006-2_NIRCam_2022-08-16": {
        "target_name": "SXDF-NB1006-2",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "ALMA [OIII]88um Emitters. Signpost of Early Stellar Buildup and Reionization in the Universe",
        "parent_obsid": "89362617",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Lyman-alpha galaxies",
        "file_name": "jw01840-o015_t011_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-08-16 06:57:35.366",
        "end_time": "2022-08-16 07:37:08.171",
        "exposure_time": "0:35:04.408000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01840-o015_t011_nircam_clear-f444w_i2d.fits",
        "size": 132114240
    },
    "J0229M0808_NIRCam_2022-08-16": {
        "target_name": "J0229M0808",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "89363520",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o005_t006_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-08-16 09:19:28.754",
        "end_time": "2022-08-16 09:49:32.548",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o005_t006_nircam_clear-f356w_i2d.fits",
        "size": 320474880
    },
    "J1743045_NIRCam_2022-08-16": {
        "target_name": "J1743045",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (A Dwarfs)",
        "parent_obsid": "89383459",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "A dwarfs",
        "file_name": "jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-08-22 10:09:42.583",
        "end_time": "2022-08-22 10:13:17.404",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "size": 287968320
    },
    "P330E_NIRCam_2022-08-17": {
        "target_name": "P330E",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F405N",
        "obs_title": "Absolute Flux Calibration (G Dwarfs)",
        "parent_obsid": "87623322",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "start_time": "2022-07-02 17:53:27.661",
        "end_time": "2022-07-02 17:56:08.688",
        "exposure_time": "0:01:04.420000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "size": 120752640
    },
    "PSRJ1748-2021B_NIRCam_2022-08-17": {
        "target_name": "PSRJ1748-2021B",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "Confirming the most massive neutron star with observations of its companion",
        "parent_obsid": "89368995",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "M dwarfs, Pulsars, White dwarfs",
        "file_name": "jw02204-o001_t001_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-08-17 08:29:30.520",
        "end_time": "2022-08-17 10:50:41.900",
        "exposure_time": "1:54:31.540000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02204-o001_t001_nircam_clear-f444w_i2d.fits",
        "size": 132298560
    },
    "J1526M2050_NIRCam_2022-08-18": {
        "target_name": "J1526M2050",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "118224856",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o071_t025_nircam_clear-f356w_i2d.fits",
        "start_time": "2023-03-03 11:15:17.368",
        "end_time": "2023-03-03 11:45:21.290",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o071_t025_nircam_clear-f356w_i2d.fits",
        "size": 320477760
    },
    "J1743045_NIRCam_2022-08-19": {
        "target_name": "J1743045",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (A Dwarfs)",
        "parent_obsid": "89383459",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "A dwarfs",
        "file_name": "jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-08-22 10:09:42.583",
        "end_time": "2022-08-22 10:13:17.404",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "size": 287968320
    },
    "J1743045_NIRCam_2022-08-20": {
        "target_name": "J1743045",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (A Dwarfs)",
        "parent_obsid": "89383459",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "A dwarfs",
        "file_name": "jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-08-22 10:09:42.583",
        "end_time": "2022-08-22 10:13:17.404",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "size": 287968320
    },
    "P330E_NIRCam_2022-08-20": {
        "target_name": "P330E",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F405N",
        "obs_title": "Absolute Flux Calibration (G Dwarfs)",
        "parent_obsid": "87623322",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "start_time": "2022-07-02 17:53:27.661",
        "end_time": "2022-07-02 17:56:08.688",
        "exposure_time": "0:01:04.420000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "size": 120752640
    },
    "P330E_NIRCam_2022-08-21": {
        "target_name": "P330E",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F405N",
        "obs_title": "Absolute Flux Calibration (G Dwarfs)",
        "parent_obsid": "87623322",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "start_time": "2022-07-02 17:53:27.661",
        "end_time": "2022-07-02 17:56:08.688",
        "exposure_time": "0:01:04.420000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "size": 120752640
    },
    "J1743045_NIRCam_2022-08-22": {
        "target_name": "J1743045",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Absolute Flux Calibration (A Dwarfs)",
        "parent_obsid": "89383459",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "A dwarfs",
        "file_name": "jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-08-22 10:09:42.583",
        "end_time": "2022-08-22 10:13:17.404",
        "exposure_time": "0:01:25.894000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01536-o077_t004_nircam_clear-f277w_i2d.fits",
        "size": 287968320
    },
    "J2232P2930_NIRCam_2022-08-10": {
        "target_name": "J2232P2930",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "88815033",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o025_t030_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-08-10 19:00:14.370",
        "end_time": "2022-08-10 19:30:18.228",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o025_t030_nircam_clear-f356w_i2d.fits",
        "size": 320477760
    },
    "P330E_NIRCam_2022-08-10": {
        "target_name": "P330E",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F405N",
        "obs_title": "Absolute Flux Calibration (G Dwarfs)",
        "parent_obsid": "87623322",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "start_time": "2022-07-02 17:53:27.661",
        "end_time": "2022-07-02 17:56:08.688",
        "exposure_time": "0:01:04.420000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "size": 120752640
    },
    "J0109M3047_NIRCam_2022-08-11": {
        "target_name": "J0109M3047",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "88793738",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o001_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-08-11 03:10:24.901",
        "end_time": "2022-08-11 03:40:28.695",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o001_t001_nircam_clear-f356w_i2d.fits",
        "size": 320474880
    },
    "J0224M4711_NIRCam_2022-08-11": {
        "target_name": "J0224M4711",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "88910354",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o003_t004_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-08-11 13:34:07.765",
        "end_time": "2022-08-11 14:04:11.623",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o003_t004_nircam_clear-f356w_i2d.fits",
        "size": 320474880
    },
    "J0244M5008_NIRCam_2022-08-11": {
        "target_name": "J0244M5008",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "88947877",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o006_t007_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-08-11 15:28:55.054",
        "end_time": "2022-08-11 15:58:58.848",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o006_t007_nircam_clear-f356w_i2d.fits",
        "size": 320474880
    },
    "SPT0418-47_NIRCam_2022-08-11": {
        "target_name": "SPT0418-47",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "TEMPLATES: Targeting Extremely Magnified Panchromatic Lensed Arcs and Their Extended Star formation",
        "parent_obsid": "88902519",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies",
        "file_name": "jw01355-o016_t001_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-08-11 17:00:46.748",
        "end_time": "2022-08-11 17:09:32.828",
        "exposure_time": "0:07:09.470000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01355-o016_t001_nircam_clear-f444w_i2d.fits",
        "size": 135956160
    },
    "CHAMMS1-C2-FIELD_NIRCam_2022-08-11": {
        "target_name": "CHAMMS1-C2-FIELD",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F410M",
        "obs_title": "IceAge: Chemical evolution of ices during star formation",
        "parent_obsid": "87623164",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Protostars",
        "file_name": "jw01309-o024_t005_nircam_clear-f410m_i2d.fits",
        "start_time": "2022-07-03 08:59:57.014",
        "end_time": "2022-07-03 10:52:55.605",
        "exposure_time": "0:05:22.104000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01309-o024_t005_nircam_clear-f410m_i2d.fits",
        "size": 495648000
    },
    "CHAMMS1-C2-FIELD_NIRCam_2022-08-12": {
        "target_name": "CHAMMS1-C2-FIELD",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F410M",
        "obs_title": "IceAge: Chemical evolution of ices during star formation",
        "parent_obsid": "87623164",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Protostars",
        "file_name": "jw01309-o024_t005_nircam_clear-f410m_i2d.fits",
        "start_time": "2022-07-03 08:59:57.014",
        "end_time": "2022-07-03 10:52:55.605",
        "exposure_time": "0:05:22.104000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01309-o024_t005_nircam_clear-f410m_i2d.fits",
        "size": 495648000
    },
    "GANYMEDE-OFFSET-V3_NIRCam_2022-08-12": {
        "target_name": "GANYMEDE-OFFSET-V3",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "Absolute Brightness Measuremt of the Extragalactic Background Light Using Gallilean Satellites Eclipse Occultations",
        "parent_obsid": "89071719",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Satellite",
        "file_name": "jw02134-o003_t005_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-08-12 14:16:06.915",
        "end_time": "2022-08-12 16:13:51.755",
        "exposure_time": "1:43:04.380000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02134-o003_t005_nircam_clear-f356w_i2d.fits",
        "size": 120127680
    },
    "J0305M3150_NIRCam_2022-08-12": {
        "target_name": "J0305M3150",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "89071147",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o007_t009_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-08-12 19:37:27.851",
        "end_time": "2022-08-12 20:07:31.645",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o007_t009_nircam_clear-f356w_i2d.fits",
        "size": 320474880
    },
    "NGC-1365_NIRCam_2022-08-13": {
        "target_name": "NGC-1365",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "89120124",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw02107-o021_t003_nircam_clear-f360m_i2d.fits",
        "start_time": "2022-08-13 17:08:51.022",
        "end_time": "2022-08-13 18:34:30.702",
        "exposure_time": "0:14:18.944000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o021_t003_nircam_clear-f360m_i2d.fits",
        "size": 249750720
    },
    "P330E_NIRCam_2022-08-14": {
        "target_name": "P330E",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F405N",
        "obs_title": "Absolute Flux Calibration (G Dwarfs)",
        "parent_obsid": "87623322",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "G dwarfs",
        "file_name": "jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "start_time": "2022-07-02 17:53:27.661",
        "end_time": "2022-07-02 17:56:08.688",
        "exposure_time": "0:01:04.420000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01538-o059_t002_nircam_f405n-f444w_i2d.fits",
        "size": 120752640
    },
    "NGC891-DISK-NORTH1_NIRCam_2022-08-15": {
        "target_name": "NGC891-DISK-NORTH1",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F150W",
        "obs_title": "Structure formation and baryonic cycling in the edge-on galaxy NGC891",
        "parent_obsid": "89274819",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Galaxy bulges, Galaxy disks, Spiral galaxies",
        "file_name": "jw02180-o001_t002_nircam_clear-f150w-sub640_i2d.fits",
        "start_time": "2022-08-15 02:21:02.965",
        "end_time": "2022-08-15 03:33:51.666",
        "exposure_time": "0:10:02.748000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02180-o001_t002_nircam_clear-f150w-sub640_i2d.fits",
        "size": 494435520
    },
    "NGC891-DISK-NORTH_NIRCam_2022-08-15": {
        "target_name": "NGC891-DISK-NORTH",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F150W",
        "obs_title": "Structure formation and baryonic cycling in the edge-on galaxy NGC891",
        "parent_obsid": "89272617",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Galaxy disks, Galaxy halos, Spiral galaxies, Tidal tails",
        "file_name": "jw02180-o002_t001_nircam_clear-f150w-sub640_i2d.fits",
        "start_time": "2022-08-15 03:54:20.976",
        "end_time": "2022-08-15 05:08:04.268",
        "exposure_time": "0:10:02.748000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02180-o002_t001_nircam_clear-f150w-sub640_i2d.fits",
        "size": 494668800
    },
    "NGC891-DISK-NORTH3_NIRCam_2022-08-15": {
        "target_name": "NGC891-DISK-NORTH3",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F150W",
        "obs_title": "Structure formation and baryonic cycling in the edge-on galaxy NGC891",
        "parent_obsid": "89200305",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Galaxy disks, Spiral galaxies",
        "file_name": "jw02180-o003_t003_nircam_clear-f150w-sub640_i2d.fits",
        "start_time": "2022-08-15 05:25:32.714",
        "end_time": "2022-08-15 06:39:04.359",
        "exposure_time": "0:10:02.748000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02180-o003_t003_nircam_clear-f150w-sub640_i2d.fits",
        "size": 494435520
    },
    "NGC891-BACKGROUND_NIRCam_2022-08-15": {
        "target_name": "NGC891-BACKGROUND",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Structure formation and baryonic cycling in the edge-on galaxy NGC891",
        "parent_obsid": "107216696",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Galaxy disks, Galaxy halos, Spiral galaxies",
        "file_name": "jw02180-o011_t009_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-12-23 09:43:27.917",
        "end_time": "2022-12-23 10:26:46.273",
        "exposure_time": "0:34:21.460000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02180-o011_t009_nircam_clear-f277w_i2d.fits",
        "size": 290952000
    },
    "NGC-5139_NIRCam_2022-08-02": {
        "target_name": "NGC-5139",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F470N",
        "obs_title": "NIRCam Persistence Characterization",
        "parent_obsid": "87841163",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Globular star clusters",
        "file_name": "jw01478-o015_t003_nircam_f444w-f470n_i2d.fits",
        "start_time": "2022-08-03 04:16:36.799",
        "end_time": "2022-08-03 04:27:42.499",
        "exposure_time": "0:03:34.735000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01478-o015_t003_nircam_f444w-f470n_i2d.fits",
        "size": 288115200
    },
    "NGC-5139_NIRCam_2022-08-03": {
        "target_name": "NGC-5139",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F444W;F470N",
        "obs_title": "NIRCam Persistence Characterization",
        "parent_obsid": "87841163",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Globular star clusters",
        "file_name": "jw01478-o015_t003_nircam_f444w-f470n_i2d.fits",
        "start_time": "2022-08-03 04:16:36.799",
        "end_time": "2022-08-03 04:27:42.499",
        "exposure_time": "0:03:34.735000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01478-o015_t003_nircam_f444w-f470n_i2d.fits",
        "size": 288115200
    },
    "NGC6720_NIRCam_2022-08-04": {
        "target_name": "NGC6720",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "The Ring Nebula as a laboratory for the interaction of molecules, PAHs and dust in strong UV radiation fields",
        "parent_obsid": "87872846",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planetary nebulae nuclei",
        "file_name": "jw01558-o005_t001_nircam_clear-f335m_i2d.fits",
        "start_time": "2022-08-04 01:37:40.212",
        "end_time": "2022-08-04 01:57:10.552",
        "exposure_time": "0:08:03.156000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01558-o005_t001_nircam_clear-f335m_i2d.fits",
        "size": 150076800
    },
    "HD149026_NIRCam_2022-08-04": {
        "target_name": "HD149026",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "WLP4",
        "obs_title": "Extrasolar Planet Science with JWST",
        "parent_obsid": "88548748",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Exoplanet Systems, G dwarfs",
        "file_name": "jw01274005001_04103_00001-seg008_nrca1_calints.fits",
        "start_time": "2022-08-04 04:05:55.496",
        "end_time": "2022-08-04 12:21:45.395",
        "exposure_time": "7:49:44.115000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw01274005001_04103_00001-seg008_nrca1_calints.fits",
        "size": 478782720
    },
    "J0218P0007_NIRCam_2022-08-07": {
        "target_name": "J0218P0007",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "88628090",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o002_t003_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-08-10 12:15:51.866",
        "end_time": "2022-08-10 12:45:55.660",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o002_t003_nircam_clear-f356w_i2d.fits",
        "size": 320477760
    },
    "J0226P0302_NIRCam_2022-08-07": {
        "target_name": "J0226P0302",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "88631843",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o004_t005_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-08-10 14:05:59.027",
        "end_time": "2022-08-10 14:36:02.821",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o004_t005_nircam_clear-f356w_i2d.fits",
        "size": 320474880
    },
    "J0229M0808_NIRCam_2022-08-07": {
        "target_name": "J0229M0808",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A SPectroscopic survey of biased halos In the Reionization Era (ASPIRE): A JWST Quasar Legacy Survey",
        "parent_obsid": "89363520",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "High-redshift galaxies, Quasars",
        "file_name": "jw02078-o005_t006_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-08-16 09:19:28.754",
        "end_time": "2022-08-16 09:49:32.548",
        "exposure_time": "0:23:37.254000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02078-o005_t006_nircam_clear-f356w_i2d.fits",
        "size": 320474880
    },
    "NGC628-NIRCAM_NIRCam_2022-07-29": {
        "target_name": "NGC628-NIRCAM",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F335M",
        "obs_title": "JWST probes Feedback in Emerging extrAgalactic Star clusTers: JWST-FEAST",
        "parent_obsid": "87767066",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Spiral galaxies",
        "file_name": "jw01783-o004_t008_nircam_clear-f335m_i2d.fits",
        "start_time": "2022-07-29 06:30:09.483",
        "end_time": "2022-07-29 09:11:42.893",
        "exposure_time": "0:48:40.400000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01783-o004_t008_nircam_clear-f335m_i2d.fits",
        "size": 349646400
    },
    "VV114-CENTERED_NIRCam_2022-07-29": {
        "target_name": "VV114-CENTERED",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F444W",
        "obs_title": "A JWST Study of the Starburst-AGN Connection in Merging LIRGs",
        "parent_obsid": "87767289",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Infrared galaxies, Interacting galaxies",
        "file_name": "jw01328-o024_t021_nircam_clear-f444w_i2d.fits",
        "start_time": "2022-07-29 11:04:02.833",
        "end_time": "2022-07-29 11:25:20.549",
        "exposure_time": "0:09:39.789000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01328-o024_t021_nircam_clear-f444w_i2d.fits",
        "size": 150217920
    },
    "EL-GORDO_NIRCam_2022-07-29": {
        "target_name": "EL-GORDO",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "JWST Medium-Deep Fields -- Windhorst IDS GTO Program",
        "parent_obsid": "87767976",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw01176-o241_t012_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-07-29 20:40:20.145",
        "end_time": "2022-07-29 21:16:18.306",
        "exposure_time": "0:31:29.672000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01176-o241_t012_nircam_clear-f356w_i2d.fits",
        "size": 306550080
    },
    "WHL0137-08_NIRCam_2022-07-30": {
        "target_name": "WHL0137-08",
        "target_classification": "Clusters of Galaxies",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "A Strongly Magnified Individual Star and Parsec-Scale Clusters Observed in the First Billion Years at z = 6",
        "parent_obsid": "87768687",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Rich clusters",
        "file_name": "jw02282-o010_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-07-30 16:31:53.335",
        "end_time": "2022-07-30 17:11:26.204",
        "exposure_time": "0:35:04.408000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02282-o010_t001_nircam_clear-f356w_i2d.fits",
        "size": 306555840
    },
    "LAWD-66-SOURCE_NIRCam_2022-07-27": {
        "target_name": "LAWD-66-SOURCE",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F322W2;F323N",
        "obs_title": "Accurate Mass Determination of the Nearby Single White Dwarf L845-70 through Astrometric Microlensing",
        "parent_obsid": "87742342",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "K dwarfs",
        "file_name": "jw02692-o001_t001_nircam_f322w2-f323n_i2d.fits",
        "start_time": "2022-07-27 05:48:42.780",
        "end_time": "2022-07-27 06:15:54.788",
        "exposure_time": "0:21:28.412000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02692-o001_t001_nircam_f322w2-f323n_i2d.fits",
        "size": 120804480
    },
    "JUPITER_NIRCam_2022-07-27": {
        "target_name": "JUPITER",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "CAR FGS-017 Straylight for Moving Targets (All SIs)",
        "parent_obsid": "87529766",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet",
        "file_name": "jw01022-o010_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-07-03 13:39:02.235",
        "end_time": "2022-07-03 13:42:37.034",
        "exposure_time": "0:03:13.262000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01022-o010_t001_nircam_clear-f356w_i2d.fits",
        "size": 286931520
    },
    "J182831.08+265037.7_NIRCam_2022-07-28": {
        "target_name": "J182831.08+265037.7",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F360M",
        "obs_title": "NIRCam Y-Dwarfs",
        "parent_obsid": "87765775",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Brown dwarfs",
        "file_name": "jw01189-o010_t005_nircam_clear-f360m_i2d.fits",
        "start_time": "2022-07-28 12:07:16.876",
        "end_time": "2022-07-28 12:38:57.337",
        "exposure_time": "0:27:11.988000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01189-o010_t005_nircam_clear-f360m_i2d.fits",
        "size": 155096640
    },
    "J182831.08+265037.7_NIRISS_2022-07-28": {
        "target_name": "J182831.08+265037.7",
        "target_classification": "Star",
        "instrument_name": "NIRISS/image",
        "filters": "F480M",
        "obs_title": "NIRCam Y-Dwarfs",
        "parent_obsid": "87765875",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Brown dwarfs",
        "file_name": "jw01189-o017_t005_niriss_clearp-f480m_i2d.fits",
        "start_time": "2022-07-28 17:09:31.001",
        "end_time": "2022-07-28 18:13:01.285",
        "exposure_time": "0:35:47.354000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01189-o017_t005_niriss_clearp-f480m_i2d.fits",
        "size": 118128960
    },
    "JUPITER_NIRCam_2022-07-28": {
        "target_name": "JUPITER",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "CAR FGS-017 Straylight for Moving Targets (All SIs)",
        "parent_obsid": "87529766",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet",
        "file_name": "jw01022-o010_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-07-03 13:39:02.235",
        "end_time": "2022-07-03 13:42:37.034",
        "exposure_time": "0:03:13.262000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01022-o010_t001_nircam_clear-f356w_i2d.fits",
        "size": 286931520
    },
    "ANDROMEDA-I_NIRCam_2022-07-28": {
        "target_name": "ANDROMEDA-I",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Dynamics of the Andromeda Galaxy Satellite System",
        "parent_obsid": "87770087",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf galaxies, Dwarf spheroidal galaxies",
        "file_name": "jw01305-o001_t001_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-07-28 21:57:00.129",
        "end_time": "2022-07-28 22:29:02.034",
        "exposure_time": "0:25:56.830000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01305-o001_t001_nircam_clear-f277w_i2d.fits",
        "size": 288276480
    },
    "ANDROMEDA-III_NIRCam_2022-07-28": {
        "target_name": "ANDROMEDA-III",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Dynamics of the Andromeda Galaxy Satellite System",
        "parent_obsid": "87766220",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf galaxies, Dwarf spheroidal galaxies",
        "file_name": "jw01305-o002_t002_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-07-28 22:57:44.601",
        "end_time": "2022-07-28 23:29:46.511",
        "exposure_time": "0:25:56.830000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01305-o002_t002_nircam_clear-f277w_i2d.fits",
        "size": 288276480
    },
    "ANDROMEDA-XIV_NIRCam_2022-07-28": {
        "target_name": "ANDROMEDA-XIV",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Dynamics of the Andromeda Galaxy Satellite System",
        "parent_obsid": "87766244",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf galaxies, Dwarf spheroidal galaxies",
        "file_name": "jw01305-o003_t003_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-07-28 23:54:01.110",
        "end_time": "2022-07-29 00:25:52.267",
        "exposure_time": "0:25:56.830000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01305-o003_t003_nircam_clear-f277w_i2d.fits",
        "size": 288276480
    },
    "ANDROMEDA-XVII_NIRCam_2022-07-29": {
        "target_name": "ANDROMEDA-XVII",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "Dynamics of the Andromeda Galaxy Satellite System",
        "parent_obsid": "87766841",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf galaxies, Dwarf spheroidal galaxies",
        "file_name": "jw01305-o004_t004_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-07-29 00:55:48.498",
        "end_time": "2022-07-29 01:27:50.472",
        "exposure_time": "0:25:56.830000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01305-o004_t004_nircam_clear-f277w_i2d.fits",
        "size": 288276480
    },
    "JUPITER_NIRCam_2022-07-29": {
        "target_name": "JUPITER",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "CAR FGS-017 Straylight for Moving Targets (All SIs)",
        "parent_obsid": "87529766",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet",
        "file_name": "jw01022-o010_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-07-03 13:39:02.235",
        "end_time": "2022-07-03 13:42:37.034",
        "exposure_time": "0:03:13.262000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01022-o010_t001_nircam_clear-f356w_i2d.fits",
        "size": 286931520
    },
    "WASP-39_NIRCam_2022-07-22": {
        "target_name": "WASP-39",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "F210M;WLP8",
        "obs_title": "The Transiting Exoplanet Community Early Release Science Program",
        "parent_obsid": "87681962",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Exoplanet Systems",
        "file_name": "jw01366002001_04103_00001-seg004_nrca3_calints.fits",
        "start_time": "2022-07-22 19:25:38.130",
        "end_time": "2022-07-23 03:38:31.442",
        "exposure_time": "8:04:40.424000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw01366002001_04103_00001-seg004_nrca3_calints.fits",
        "size": 115444800
    },
    "WLM_NIRCam_2022-07-23": {
        "target_name": "WLM",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F250M",
        "obs_title": "The Resolved Stellar Populations Early Release Science Program",
        "parent_obsid": "87685751",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Dwarf galaxies, Dwarf irregular galaxies",
        "file_name": "jw01334-o005_t002_nircam_clear-f250m_i2d.fits",
        "start_time": "2022-07-24 08:00:09.287",
        "end_time": "2022-07-24 14:40:17.039",
        "exposure_time": "6:30:49.104000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01334-o005_t002_nircam_clear-f250m_i2d.fits",
        "size": 289114560
    },
    "NGC-346_NIRCam_2022-07-16": {
        "target_name": "NGC-346",
        "target_classification": "Stellar Cluster",
        "instrument_name": "NIRCam/image",
        "filters": "F277W",
        "obs_title": "NGC 346: Star Formation at Low Metallicity in the Small Magellanic Cloud",
        "parent_obsid": "87630462",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "OB associations, Young star clusters",
        "file_name": "jw01227-o025_t005_nircam_clear-f277w_i2d.fits",
        "start_time": "2022-07-16 11:04:52.491",
        "end_time": "2022-07-16 11:19:22.214",
        "exposure_time": "0:10:01.260000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01227-o025_t005_nircam_clear-f277w_i2d.fits",
        "size": 291012480
    },
    "NGC-628_NIRCam_2022-07-17": {
        "target_name": "NGC-628",
        "target_classification": "Galaxy",
        "instrument_name": "NIRCam/image",
        "filters": "F300M",
        "obs_title": "A JWST-HST-VLT/MUSE-ALMA Treasury of Star Formation in Nearby Galaxies",
        "parent_obsid": "87632983",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Barred spiral galaxies",
        "file_name": "jw02107-o040_t018_nircam_clear-f300m_i2d.fits",
        "start_time": "2022-07-17 14:22:36.051",
        "end_time": "2022-07-17 15:51:48.022",
        "exposure_time": "0:12:53.048000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02107-o040_t018_nircam_clear-f300m_i2d.fits",
        "size": 250387200
    },
    "NEPTUNE_NIRCam_2022-07-12": {
        "target_name": "NEPTUNE",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F300M",
        "obs_title": "JWST Cycle 1 Outreach Campaign",
        "parent_obsid": "87626084",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet",
        "file_name": "jw02739-o004_t003_nircam_clear-f300m_i2d.fits",
        "start_time": "2022-07-12 06:28:25.913",
        "end_time": "2022-07-12 07:52:32.300",
        "exposure_time": "0:30:25.250000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw02739-o004_t003_nircam_clear-f300m_i2d.fits",
        "size": 137859840
    },
    "JUPITER_NIRCam_2022-07-14": {
        "target_name": "JUPITER",
        "target_classification": "Solar System",
        "instrument_name": "NIRCam/image",
        "filters": "F356W",
        "obs_title": "CAR FGS-017 Straylight for Moving Targets (All SIs)",
        "parent_obsid": "87529766",
        "description": "exposure/target (L2b/L3): rectified 2D image",
        "keywords": "Planet",
        "file_name": "jw01022-o010_t001_nircam_clear-f356w_i2d.fits",
        "start_time": "2022-07-03 13:39:02.235",
        "end_time": "2022-07-03 13:42:37.034",
        "exposure_time": "0:03:13.262000",
        "calib_level": 3,
        "fits_url": "mast:JWST/product/jw01022-o010_t001_nircam_clear-f356w_i2d.fits",
        "size": 286931520
    },
    "HD149026_NIRCam_2022-07-15": {
        "target_name": "HD149026",
        "target_classification": "Star",
        "instrument_name": "NIRCam/image",
        "filters": "WLP4",
        "obs_title": "Extrasolar Planet Science with JWST",
        "parent_obsid": "88548748",
        "description": "exposure (L2b): 3D calibrated exposure",
        "keywords": "Exoplanet Systems, G dwarfs",
        "file_name": "jw01274005001_04103_00001-seg008_nrca1_calints.fits",
        "start_time": "2022-08-04 04:05:55.496",
        "end_time": "2022-08-04 12:21:45.395",
        "exposure_time": "7:49:44.115000",
        "calib_level": 2,
        "fits_url": "mast:JWST/product/jw01274005001_04103_00001-seg008_nrca1_calints.fits",
        "size": 478782720
    }
};
export default datav7
