import React from "react";
import SourcesJS from "../SourcesJS.js";
import Footer from "../Footer.js";
import '../../App.js';
import "./Sources.css";
function Sources () {
    return (
        <>
        <div className="SourcesJs-cont">
            <SourcesJS />
            </div>
            <Footer />
        </>
  )
}
export default Sources
